import { render, staticRenderFns } from "./ThaiQrPayment.vue?vue&type=template&id=53bf6618&scoped=true"
import script from "./ThaiQrPayment.vue?vue&type=script&lang=js"
export * from "./ThaiQrPayment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53bf6618",
  null
  
)

export default component.exports