<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1 class="text-h5 font-weight-bold my-4">{{ $t('ร้านค้าที่คุณเปิดกับ LnwShop') }} <v-chip v-if="myShops" class="ml-1" small>{{ (myShops.length?myShops.length:'0')}}</v-chip></h1>
            </v-col>
            <template v-if="myShops != null && myShops.length">
                <v-col v-for="shop in myShops"
                   :key="shop.shop_id"
                   cols="12"
                   xl="3"
                   md="4"
                   sm="6"
                   :class="isDesktop?'mb-12':'mb-6'"
                   class="d-flex align-stretch p-0">
                    <ShopCard :my_shop="true" :shop="shop"></ShopCard>
                </v-col>
            </template>
            <template v-else-if="myShops?.length === 0">
                <v-col
                        v-for="shop in zeroShop"
                        :key="shop.webname"
                        cols="12"
                        xl="3"
                        md="4"
                        :class="isDesktop?'mb-12':'mb-6'"
                        class="d-flex align-stretch p-0">
                    <ShopCard :my_shop="false" :zero_shop="true" :shop="shop"></ShopCard>
                </v-col>
            </template>
            <template v-else>
                <v-col
                        v-for="i in 3" :key="i"
                        cols="12"
                        xl="3"
                        md="4"
                        :class="isDesktop?'mb-12':'mb-6'"
                        class="p-0"
                >
                    <v-skeleton-loader
                            style="padding: 16px;"
                            type="image, article"
                    ></v-skeleton-loader>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>
<script>
import {mapActions, mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import ShopCard from "@/components/App/components/ShopCard";
export default {
    name: "MyShops",
    mixins: [
        MixinsHelper,
    ],
    components: {
        ShopCard
    },
    data(){
        return {
            // myShops: []
        };
    },
    methods: {
        ...mapActions({
            fetchMyShops: 'user/fetchMyShops',
        }),
    },
    computed: {
        ...mapState({
            myShops: state => state.user.myShops,
        }),
        zeroShop(){
            return [
                {
                    "shop_id": "0",
                    "webname": "openshop",
                    "url": "https://www.lnwshop.com",
                    "shopname": this.$t('เริ่มธุรกิจกับ LnwShop'),
                    "avatar": "https://www.lnwshop.com/images/noAvatarShop.png",
                    "favicon": "https://www.lnwshop.com/images/noAvatarShop.png",
                    "description":  this.$t('เทพช็อป #1 แพลทฟอร์มออนไลน์สำหรับทุกคน') + ' ' + this.$t('เริ่มต้นทำตามความฝัน สร้างธุรกิจของคุณได้ทันที'),
                    "close_status": null
                }
            ];
        }
    },
    mounted(){
        if(this.myShops == null){
            this.fetchMyShops();
            // this.myShops = [];
        }
    }
};
</script>