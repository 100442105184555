<template>
    <v-card class="mx-3 shop-card text-decoration-none overflow-hidden  p-0 position-relative"
            elevation="24"
            link
            :style="'background-image : url('+shop.avatar+');'"
            :to="'/shop/' + shop.webname">
        <div :class="blurryClass">
            <v-img :src="shop.avatar"
                   aspect-ratio="1"></v-img>
            <div class="just_flex mb-4">
                <div class="d-flex ml-4">
                    <v-img class="rounded-lg mt-4"
                           :src="shop.favicon?shop.favicon:shop.avatar"
                           width="50"
                           height="50"
                           aspect-ratio="1"></v-img>
                    <div>
                        <template>
                            <v-card-subtitle v-if="shop.close_status === null"
                                             class="text-success line-clamp-1 text-uppercase text-caption font-weight-bold pb-0"
                                             style="font-size: 0.6em !important;">
                                Open Now
                            </v-card-subtitle>
                            <v-card-subtitle v-else-if="shop.close_status === 'inactive'"
                                             class="text-error line-clamp-1 grey--text text-uppercase text-caption font-weight-bold pb-0"
                                             style="font-size: 0.6em !important;">
                                Closed Permanently
                            </v-card-subtitle>
                            <v-card-subtitle v-else-if="shop.close_status === 'temporary'"
                                             class="text-warning line-clamp-1 grey--text text-uppercase text-caption font-weight-bold pb-0"
                                             style="font-size: 0.6em !important;">
                                Closed Temporarily
                            </v-card-subtitle>
                        </template>
                        <v-card-title class="pt-0 pb-0" style="margin-top: -0.125rem;">
                            <div class="line-clamp-1"
                                 style="font-size: 0.8em;">{{ shop.shopname }}
                            </div>
                        </v-card-title>
                    </div>
                </div>
                <v-btn @click.stop=""
                       target="_blank"
                       :href="shop.url"
                       small
                       elevation="0"
                       class="primary--text rounded-xxl mr-4 text-caption font-weight-bold">
                    {{ zero_shop?'START':'SHOP' }}
                </v-btn>
            </div>
            <v-card-text v-if="!my_shop" class="pt-0 text-body-2"
                         style="opacity: 0.8;" :style="isDesktop?'min-height: 60px;':''">
                <div class="line-clamp-2"
                     style="font-size: 0.9em;">{{ shop.description }}
                </div>
            </v-card-text>
            <v-card-actions v-if="my_shop" class="px-4">
                <v-btn
                        target="_blank"
                        @click.stop=""
                        block
                       :href="'https://a.lnwstore.com/' + shop.webname"
                       large
                       class="mb-2"
                       color="primary">
                    {{ $t('จัดการหลังร้าน') }} <v-icon class="ml-2" small>mdi-arrow-right</v-icon>
                </v-btn>
            </v-card-actions>
        </div>
    </v-card>
</template>
<script>
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "ShopCard.vue",
    mixins: [
        MixinsHelper,
    ],
    props: ['shop', 'my_shop', 'zero_shop']
}
</script>
<style scoped></style>