<template>
    <v-sheet id="lnwme-layout"
             :class="{ 'pwa': isPwaApp, 'app_page' : isAppPage}"
             elevation="0">
        <template v-if="pageReady">
            <!-- overlay refresh หน้า page      -->
            <v-overlay :value="overlay"
                       opacity="0.7"
                       z-index="999">
                <div class="center">
                    <v-row class="fill-height px-4 py-3"
                           align-content="center"
                           justify="center">
                        <v-col class="text-subtitle-1 text-center"
                               cols="12">
                            {{ $t('กรุณารอสักครู่ กำลังดำเนินการ') }}...
                        </v-col>
                        <v-col cols="12">
                            <v-progress-linear color="primary accent-4"
                                               indeterminate
                                               rounded
                                               height="6"></v-progress-linear>
                        </v-col>
                    </v-row>
                </div>
            </v-overlay>
            <slot name="drawer"></slot>
            <the-user-drawer v-model="drawer"></the-user-drawer>
            <v-app-bar :fixed="!isShopHomePage || isDesktop"
                       :absolute="isShopHomePage && !isDesktop"
                       :shrink-on-scroll="isShopHomePage && !isDesktop"
                       :prominent="isShopHomePage && !isDesktop"
                       :src="(isShopHomePage && !isDesktop)?shopData.avatar:''"
                       :fade-img-on-scroll="isShopHomePage"
                       scroll-target="#lnwme-layout-content"
                       elevate-on-scroll
                       elevation="0"
                       style="z-index: 99"
                       :class="blurryClass"
                       :style="(isDesktop && (myOrderDrawer || appDrawer))?'padding-left: 320px':''"
                       :color="(isDesktop && !select_mode)? '': ((!isAppPage)?'primary':'')"
                       :dark="((!isDesktop && !isAppPage) || select_mode) && (!isAppPage || !isDark)"
                       :height="(isShopHomePage && !isDesktop)?240:60">
                <!--            <template v-slot:img="{ props }">-->
                <!--                <v-img-->
                <!--                    v-bind="props"-->
                <!--                    gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"-->
                <!--                ></v-img>-->
                <!--            </template>-->
                <template v-if="isShopHomePage && !isDesktop"
                          v-slot:img="{ props }">
                    <v-img
                            v-bind="props"
                            gradient="to top, rgba(0,0,0,.7), transparent 50%"
                    ></v-img>
                </template>
                <slot name="toolbar"></slot>
                <div class="user ml-3 flex_center" style="height: 48px;">
                    <div v-if="userData"
                         class="avatar"
                         @click.stop="drawer = !drawer">
                        <img alt=""
                             :src="userData.avatar"/>
                    </div>
                    <div v-else-if="false"
                         class="avatar flex_center">
                        <v-icon>mdi-login</v-icon>
                    </div>
                    <div class="flag-container"
                         @click="toggleLangLnwme()">
                        <div class="flag"
                             :class="$i18n.locale + '-flag'"></div>
                        <span v-if="isDesktop"
                              class="text-uppercase">{{ $i18n.locale }}</span>
                    </div>
                    <noti-popup :isAppPage="isAppPage"></noti-popup>
                </div>
            </v-app-bar>
            <div id="lnwme-layout-content"
                 ref="lnwmeLayoutContent"
                 :style="(isShopHomePage && !isDesktop)?'padding-top: 260px':((skipLogin)?'padding-top: 60px; padding-bottom: 50px;':'')"
                 class="overflow-y-auto">
                <template v-if="isThisPageLogin || !isLoginRequired">
                    <slot></slot>
                    <template v-if="isAppPage">

                    </template>
                    <template v-else>
                        <ContactButton ref="contactButtonComponent"></ContactButton>
                    </template>
                </template>
                <template v-else>
                    <v-sheet style="height: calc(100svh - 120px)"></v-sheet>
                    <component :is="isDesktop?'VDialog':'VBottomSheet'"
                               v-model="isNotLogin"
                               persistent
                               :max-width="isDesktop?'600px':'100%'"
                               :transition="isDesktop?'dialog-transition':'dialog-bottom-transition'">
                        <v-card elevation="0">
                            <LoginComponent></LoginComponent>
<!--                            <LoginComponent></LoginComponent>-->
                        </v-card>
                    </component>
                </template>
                <v-footer :style="isDesktop?'':'z-index: 1'" class="footer" :class="blurryClass">
                    <footer-component></footer-component>
                </v-footer>
            </div>
            <slot name="bottom-navigation"></slot>
            <v-bottom-navigation v-if="!isAppPage && shopData && isThisPageLogin && !isDesktop"
                                 style="padding-right: 90px;  box-sizing: border-box"
                                 fixed
                                 :height="isPwaApp?70:60"
                                 color="primary"
                                 grow>
                <v-btn class="mr-1"
                       :href="shopData.url">
                    <span>{{ $t('ร้านค้า') }}</span> <img style="width: 24px; height: 24px;"
                                                          :src="shopData.favicon?shopData.favicon:shopData.avatar"
                                                          class="rounded mb-1"/>
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn @click="$emit('triggerGoToHomePage')">
                    <span>{{ $t('ภาพรวม') }}</span>
                    <v-icon class="mb-1">mdi-store-outline</v-icon>
                </v-btn>
                <v-btn @click="$emit('triggerGoToMyOrders')">
                    <span>{{ $t('ประวัติซื้อ') }}</span>
                    <v-icon class="mb-1">mdi-history</v-icon>
                </v-btn>
                <v-btn @click="$emit('triggerInformPayment')">
                    <span>{{ $t('แจ้งโอน') }}</span>
                    <v-icon class="mb-1">mdi-receipt-text-plus-outline</v-icon>
                </v-btn>
<!--                <v-btn :href="accountUrl('manage')"-->
<!--                       target="_blank">-->
<!--                    <span>{{ $t('ข้อมูลบุคคล') }}</span>-->
<!--                    <v-icon>mdi-account-circle</v-icon>-->
<!--                </v-btn>-->
<!--                <v-btn :href="accountUrl('manage/notifications')"-->
<!--                       target="_blank">-->
<!--                    <span>{{ $t('แจ้งเตือน') }}</span>-->
<!--                    <v-icon>mdi-bell</v-icon>-->
<!--                </v-btn>-->
            </v-bottom-navigation>
        </template>
        <template v-else>
            <div class="d-flex align-center justify-center" style="width: 100vw; height: 100vh;">
                <img style="height: 20px;" src="@/assets/lnw_new_logo.png"/>
            </div>
        </template>
        <!-- เส้น loading ajax -->
        <div class="fixed-top">
            <v-progress-linear v-if="isAjaxLoading || isAjaxResponse"
                               class="fixed-top"
                               :indeterminate="isAjaxLoading"
                               value="100"
                               :color="(isAjaxLoading)?'white':'success'"></v-progress-linear>
        </div>
        <!-- snackbar สำหรับแสดงว่า Load เรียบร้อย -->
        <!-- ไม่แสดงว่า "อัพเดทแล้ว" ใช้ v-progress-linear พอ -->
        <v-snackbar v-if="false && !isAjaxResponseMessage && isAjaxResponse"
                    v-model="isAjaxResponse"
                    timeout="3000"
                    :top="isDesktop"
                    min-width="150"
                    max-width="100%"
                    :right="isDesktop"
                    :bottom="!isDesktop"
                    color="success"
                    text
                    :rounded="isDesktop?'':'pill'"
                    outlined
                    :style="isDesktop?'margin-top: 50px;':'margin-bottom: 50px;'"
                    style="padding: 20px 15px 15px; z-index: 9999 !important;">
            {{ $t('อัพเดทแล้ว') }}
            <template v-slot:action="{ attrs }">
                <b style="cursor: pointer"
                   class="mr-2 ml-0"
                   @click="isAjaxResponse = false">OK</b>
            </template>
        </v-snackbar>
        <!-- ต้องมีอันนี้ไว้ timeout ตัว progress-linear -->
        <v-snackbar v-if="!isAjaxResponseMessage && isAjaxResponse"
                    v-model="isAjaxResponse"
                    timeout="3000"
                    style="display:none">
        </v-snackbar>
        <!-- snackbar สำหรับแสดงข้อความ RESPONSE -->
        <v-snackbar v-else-if="isAjaxResponseMessage != ''"
                    v-model="isAjaxResponse"
                    timeout="20000"
                    :top="isDesktop"
                    min-width="150"
                    max-width="360"
                    :right="isDesktop"
                    :bottom="!isDesktop"
                    color="error"
                    :rounded="(isDesktop || isAjaxResponseMessage.length > 30)?'':'pill'"
                    :style="'padding: 20px 15px 15px;  z-index: 9999 !important;'">
            <span v-if="$t(isAjaxResponseMessage) != ''"
                  v-text="$t(isAjaxResponseMessage)"></span> <span v-else
                                                                   v-html="isAjaxResponseMessage"></span>
            <template v-slot:action="{ attrs }">
                <b style="cursor: pointer"
                   class="mr-2 ml-0"
                   @click="isAjaxResponse = false; isAjaxResponseMessage = ''">{{ $t('ปิด') }}</b>
            </template>
        </v-snackbar>
        <!-- snackbar สำหรับแสดงข้อความ Copy เสร็จ -->
        <v-snackbar v-model="isCopied"
                    timeout="4000"
                    :top="isDesktop"
                    min-width="150"
                    max-width="100%"
                    :right="isDesktop"
                    :bottom="!isDesktop"
                    color="success"
                    text
                    outlined
                    :style="'padding: 20px 15px 15px; z-index: 9999 !important;'">
            {{ $t('คัดลอกแล้ว') }}
            <template v-slot:action="{ attrs }">
                <b style="cursor: pointer"
                   class="mr-2 ml-0"
                   @click="isCopied = false">OK</b>
            </template>
        </v-snackbar>
    </v-sheet>
</template>
<style scoped></style>
<script>
import TheUserDrawer from "@/components/Order/TheUserDrawer.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import MixinsHandleShopData from "@/components/Order/mixins/MixinsHandleShopData";
import LoginComponent from "@/components/Authentication/LoginComponent.vue";
import FooterComponent from "@/components/Order/components/FooterComponent";
import ContactButton from "@/components/Order/ContactButton";
import NotiPopup from "@/components/App/components/NotiPopup"
import {SET_IS_AJAX_RESPONSE, SET_IS_AJAX_RESPONSE_MESSAGE} from "@/store/modules/order/mutation-types";
import {VBottomSheet, VDialog} from "vuetify/lib/components";
import SiteUrl from "@/libraries/SiteUrl.js";
import {setLocale} from "@/i18n";

export default {
    name: "LnwmeLayout",
    components: {
        TheUserDrawer,
        LoginComponent,
        ContactButton,
        VDialog,
        VBottomSheet,
        FooterComponent,
        NotiPopup
    },
    data(){
        return {
            overlay: false,
            drawer: null,
            pageReady: false,
            titleTruncated: false,
        }
    },
    props: {
        myOrderDrawer: {
            type: Boolean,
        },
        appDrawer: {
            type: Boolean,
        },
        select_mode: {
            type: Boolean,
        },
        skipLogin: {
            type: Boolean,
        },
        isAppPage: {
            type: Boolean,
        },
        isMyOrder: {
            type: Boolean,
        },
        isShopHomePage: {
            type: Boolean,
            required: false
        },
    },
    mixins: [
        MixinsDisabledIconFocus,
        MixinsHelper,
        MixinsHandleShopData,
    ],
    computed: {
        ...mapState({
            userData: state => state.user.userData,
            userDataInShop: state => state.myOrders.userDataInShop,
            shopData: state => state.myOrders.shopData,
            web_name: state => state.myOrders.web_name,
            isAjaxLoading: state => state.order.isAjaxLoading,
        }),
        ...mapGetters({
            isLogin: "user/isLogin",
        }),
        isThisPageLogin() {
          return this.isLogin || this.userDataInShop;
        },
        isNotLogin() {
          return !this.isThisPageLogin
        },
        isLoginRequired(){
            return !this.skipLogin;
        },
        isAjaxResponse: {
            get(){
                return this.$store.state.order.isAjaxResponse;
            },
            set(value){
                this.$store.commit('order/' + SET_IS_AJAX_RESPONSE, value);
            },
        },
        isAjaxResponseMessage: {
            get(){
                return this.$store.state.order.isAjaxResponseMessage;
            },
            set(value){
                this.$store.commit('order/' + SET_IS_AJAX_RESPONSE_MESSAGE, value);
            },
        },
        isCopied: {
            get(){
                return this.$store.state.order.isCopied;
            },
            set(value){
                this.$store.dispatch('order/setIsCopied', value);
            },
        },
    },
    methods: {
        ...mapMutations({
            mutationSetWebName: "myOrders/SET_WEB_NAME",
            mutationSetWebNameInOrder: "order/SET_WEB_NAME",
        }),
        ...mapActions({
            actionInitUserData: "user/initUserData",
            actionFetchOrders: "myOrders/fetchOrders",
        }),
        toggleMyOrderMenu(){
            this.$emit('toggleMyOrder');
        },
        toggleLangLnwme(){
            this.overlay = true;
            if(this.$i18n.locale == 'th'){
                setLocale('en');
            }else{
                setLocale('th');
            }
            this.overlay = false;
            // window.location.reload();
        },
        // updateIframeHeight(){
        //     let that = this;
        //     setTimeout(function (){
        //         const iframe = that.$refs.lnwmeIframe;
        //         if(iframe){
        //             iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
        //             // console.log(`${iframe.contentWindow.document.body.scrollHeight}px`);
        //         }
        //     }, 5000);
        // },
        accountUrl(uri){
            return SiteUrl.account(uri);
        },
        async handlePageReady() {
            this.pageReady = true;
            const body = document.body;
            body.classList.add("lnwme-body");
            let lang = this.$route.query.lang;
            if(lang && lang != this.$i18n.locale){
                this.toggleLangLnwme();
            }
            await this.$store.dispatch('order/setEnableSetIsAjaxResponse', true);
        },
    },

    mounted(){
        if(this.$route.params?.web_name){
            this.mutationSetWebName(this.$route.params.web_name);
            this.mutationSetWebNameInOrder(this.$route.params.web_name);
            this.actionInitUserData().then(async () => {
                await this.actionFetchOrders().then(async () => {
                    this.handleShopData();
                    this.changeThemeColor();
                    await this.handlePageReady();
                });
            });
        }else{
            this.actionInitUserData().then(async () => {
                await this.handlePageReady();
            });
        }
        let query = Object.assign({}, this.$route.query);
        let haveToReplace = false;
        if(query._lnwaccc){
            delete query._lnwaccc;
            haveToReplace = true;
        }
        if(this.isMyOrder){
            let that = this;
            this.waitForElm('#lnwme-layout-content').then((elm) => {

                elm.addEventListener("scroll", () => {
                    if(elm.scrollTop + elm.clientHeight + 1 >= elm.scrollHeight){
                        if(!that.isShopHomePage){
                            that.$emit('loadMoreOrders');
                        }
                    }
                    if(elm.scrollTop >= 150) {
                        if(!this.titleTruncated){
                            this.$emit('truncate-title', true);
                            this.titleTruncated = true;
                        }
                    }else{
                        if(this.titleTruncated){
                            this.$emit('truncate-title',false);
                            this.titleTruncated = false;
                        }
                    }
                });
            });
        }
        if(haveToReplace){
            this.$router.replace({query});
        }
    }
};
</script>