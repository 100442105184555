<template>
    <div class="mx-auto allChannel">
        <!-- bank_transfer -->
        <template v-if="payment_method_key === 'bank_transfer'">
            <!-- แสดงรายการเลขบัญชีธนาคาร เพื่อให้ COPY ไปโอนเงิน -->
            <v-card outlined>
                <v-list>
                    <div v-for="(bank, index) in bankPaymentMethodBanksForDisplay"
                         :key="bank.key">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="mb-2 flex_start">
                                    <b style="font-size: 1.5em;">{{ bank.detail.account_number }}</b>
                                    <handle-copy-component class="d-inline-block ml-1" ref="handleCopy">
                                        <v-btn small
                                               text>
                                            <div class="primary--text"
                                                 v-clipboard:copy="bank.detail.account_number">
                                                <v-icon color="primary"
                                                        x-small>mdi-content-copy
                                                </v-icon>
                                                COPY
                                            </div>
                                        </v-btn>
                                    </handle-copy-component>

                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ bank.detail.account_name }}
                                    <v-scroll-y-transition>
                                        <div>
                                            <div>{{ bank.name }}
                                            </div>
                                            <!--                                                        <div>{{bank.detail.account_type}} - -->
                                            <!--                                                             {{bank.detail.account_branch}}-->
                                            <!--                                                        </div>-->
                                        </div>
                                    </v-scroll-y-transition>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <template v-if="bank.detail.icon">
                                    <img style="height: 40px"
                                         class="m-0"
                                         :src="bank.detail.icon.url">
                                </template>
                                <template v-else>
                                    <img style="height: 40px"
                                         class="m-0"
                                         :src="`https://www.lnwpay.com/images/banks/${bank.detail.account_key}.jpg`">
                                </template>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider v-if="index !== bankPaymentMethodBanks.length -1"></v-divider>
                    </div>
                </v-list>
            </v-card>
            <!-- ปุ่มแจ้งโอนเงิน พร้อม dialog -->
            <inform-payment-dialog>
                <template v-slot:activator="{ on, attrs }">
                    <div class="flex_end">
                        <v-btn color="primary"
                               :disabled="isWaitingShippingPrice"
                               v-bind="attrs"
                               v-on="on"
                               :block="!isDesktop"
                               x-large>
                            {{ $t("แจ้งชำระเงิน (ส่งสลิป)") }}
                        </v-btn>
                    </div>
                </template>
            </inform-payment-dialog>
        </template>
        <!-- thai qr scan -->
        <template v-else-if="payment_method_key === 'thai_qr_payment'">
            <!-- แสดงรายการเลขบัญชีธนาคาร เพื่อให้ COPY ไปโอนเงิน -->

            <v-card outlined>
                <v-list style="background-color: #fafafa;">
                    <div v-for="(bank, index) in thaiQrPaymentMethodBanksForDisplay"
                         :key="bank.key">
                        <v-list-item>
                            <v-list-item-content>
                                <div class="just_flex align-start" :class="!isDesktop?'flex-wrap':''">
                                    <div class="flex pl-0 pt-4" style="font-size: 13px; flex-basis: 200px">
                                        <b><u>{{ $t("วิธีชำระด้วย QR Code") }}</u></b>
                                        <ol class="small my-4" style="line-height: 2; list-style: outside decimal; padding-left: 20px">
                                            <li>
                                                <template v-if="isDesktop">
                                                    {{ $t("Scan QR ด้วย App ธนาคาร") }}
                                                </template>
                                                <template v-else>
                                                    {{ $t("บันทึกรูป QR นี้แล้วเลือกอัพโหลดที่ App ธนาคาร") }}
                                                </template>
                                            </li>
                                            <li>{{ $t("ยืนยันการโอนเงินจนเสร็จสิ้น") }}</li>
                                            <li class="flex">
                                                <div class="d-inline-block">{{ $t("คลิก") }}</div>
                                                <inform-payment-dialog>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <div class="d-inline-block primary--text ml-1 clickable"
                                                                v-bind="attrs"
                                                             v-on="on"> {{ $t("แจ้งชำระเงิน (ส่งสลิป)") }}</div>
                                                    </template>
                                                </inform-payment-dialog></li>
                                        </ol>
                                    </div>
                                    <div class="thaiQrBox">
                                        <v-img placeholder
                                               :src="require('@/assets/payment/thaiqrpayment_header.jpeg')"></v-img>
                                        <img placeholder
                                             class="d-block"
                                               style="margin: 15px auto"
                                               max-width="200"
                                               max-height="200"
                                               height="200"
                                               :src="bank.detail.qr_url"
                                               :lazy-src="require('@/assets/lnw_logo_placeholder.png')" />

                                        {{ bank.detail.account_name }}
                                        <div class="minor">{{ bank.detail.account_number }}</div>
                                        <hr>
                                        <div class="minor">Accept All Banks | รับเงินได้จากทุกธนาคาร</div>
                                    </div>

                                </div>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index !== thaiQrPaymentMethodBanks.length -1"></v-divider>
                    </div>
                </v-list>
            </v-card>
            <div class="flex_end flex_wrap" style="gap: 1rem;">
                <v-btn v-if="canShare" color="primary"
                       @click="saveImage()"
                       outlined
                       :block="!isDesktop"
                       x-large>
                    {{ $t("บันทึกรูปภาพ QR") }}
                </v-btn>
                <!-- ปุ่มแจ้งโอนเงิน พร้อม dialog -->
                <inform-payment-dialog>
                    <template v-slot:activator="{ on, attrs }">

                            <v-btn color="primary"
                                   :disabled="isWaitingShippingPrice"
                                   v-bind="attrs"
                                   v-on="on"
                                   :block="!isDesktop"
                                   x-large>
                                {{ $t("แจ้งชำระเงิน (ส่งสลิป)") }}
                            </v-btn>
                    </template>
                </inform-payment-dialog>
            </div>
        </template>
        <!-- ช่องทาง Online Payment Gateway อื่นๆ -->
        <template v-else-if="payment_method !== null">
<!--            <pre>{{JSON.stringify(payment_method,0,2)}}</pre>-->
<!--            <pre>{{JSON.stringify(payment_provider,0,2)}}</pre>-->
            <v-card>
                <v-list>
                    <v-list-item class="justify-center">
                        {{payment_method.name}}
                    </v-list-item>
                    <v-list-item class="justify-center">
                        <img v-if="payment_provider_icon" :alt="payment_provider.key" :src="payment_provider_icon">
                        {{payment_method.name}}
                    </v-list-item>
                </v-list>
            </v-card>

        </template>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import InformPaymentDialog from "@/components/Order/components/PaymentMethod/InformPaymentDialog.vue";
import MixinsGetPaymentProviderImgUrl
    from "@/components/Order/components/PaymentMethod/components/LnwPaymentComponent/mixins/MixinsGetPaymentProviderImgUrl.vue";
import HandleCopyComponent from "@/components/Order/components/HandleCopyComponent";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import MixinsTheOrderDetailMutual from "@/components/Order/mixins/MixinsTheOrderDetailMutual";

export default {
    name: "SelectedPaymentMethodViewer",
    mixins: [
        MixinsTheOrderDetailMutual,
        MixinsGetPaymentProviderImgUrl,
        MixinsHelper
    ],
    components: {
        InformPaymentDialog,
        HandleCopyComponent
    },
    props: {
        inputValue: {
            type: Object,
            required: true,
        },
        paymentMethods: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            orderData: 'order/order',
            thaiQrPaymentMethodBanks: 'order/thaiQrPaymentMethod',
            bankPaymentMethodBanks: 'order/bankPaymentMethodDropdownOptions',
            isWaitingShippingPrice: 'order/isWaitingShippingPrice',
        }),
        canShare() {
            return navigator.share && window.isSecureContext;
        },
        payment_method_key() {
            if (this.inputValue === null)
                return null;
            return this.inputValue.selectedMethod;
        },
        payment_method() {
            for (const payment_method of this.paymentMethods) {
                if (payment_method.key === this.payment_method_key) {
                    return payment_method;
                }
            }
            return null;
        },
        payment_provider() {
            if (this.inputValue[this.payment_method_key]
                && typeof this.inputValue[this.payment_method_key] === 'object'
                && this.inputValue[this.payment_method_key].provider_key) {
                const provider_key = this.inputValue[this.payment_method_key].provider_key;
                if (Array.isArray(this.payment_method.providers)) {
                    for (const payment_provider of this.payment_method.providers) {
                        if (payment_provider.key === provider_key) {
                            return payment_provider;
                        }
                    }
                }
            }
            return null;
        },
        payment_provider_icon() {
            return this.getPaymentProviderImgUrl(this.payment_method_key, this.payment_method.key, {provider: this.payment_provider});
        },
        bankPaymentMethodBanksForDisplay() {
            const list = [];
            for (let o of this.bankPaymentMethodBanks) {
                if (o.key === this.orderData.payment_provider) {
                    list.push(o);
                    break;
                }
            }
            //console.log(list);

            if (list.length === 0)
                return this.bankPaymentMethodBanks;

            return list;
        },
        thaiQrPaymentMethodBanksForDisplay() {
            const list = [];
            for (let o of this.thaiQrPaymentMethodBanks) {
                if (o.key === this.orderData.payment_provider) {
                    list.push(o);
                    break;
                }else{
                    // เพิ่มกรณีที่ key มาไม่ถูก
                    let o_key = o.key;
                    o_key = o_key.replace("bank_transfer", "billpayment");
                    if(o_key === this.orderData.payment_provider){
                        list.push(o);
                        break;
                    }
                }
            }

            if (list.length === 0)
                return this.thaiQrPaymentMethodBanks;

            return list;
        },
    },
    methods: {
        saveImage() {
            navigator.share({
                title: 'QR image',
                text: 'Download this qr image',
                url: this.thaiQrPaymentMethodBanksForDisplay[0]?.detail.qr_url,
            }).then(() => {
                }).catch(() => {
                    window.open(this.thaiQrPaymentMethodBanksForDisplay[0]?.detail.qr_url, '_blank');
                });
        }
    }
}
</script>

<style scoped>

</style>