<script>

export default {
  name: "PocNoti",
  data() {
    return {
      token: '',
    }
  },
  methods: {
    registerWebPushNotification(){
      if("serviceWorker" in navigator && "PushManager" in window && "Notification" in window){
        // ขอสิทธิ์จากผู้ใช้สำหรับการแสดง Notification
        Notification.requestPermission().then((permission) => {
          if(permission === "granted"){
            navigator.serviceWorker.register("/service-worker.js").then(async(registration) => {
              try {
                const vapidPublicKey = 'BEjmIalPbvv8nWez1MABAtQM3o84Gi3zLSX2CQWMHCF7_9h_U2S4dlhFgruNP6QbVdrDXmN1GGxR59COTa4LBIQ';
                const convertedVapidKey = this.urlBase64ToUint8Array(vapidPublicKey)

                const subscription = await registration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: convertedVapidKey
                });

                // ส่งข้อมูลการสมัครไปยัง backend เพื่อเอาไว้ยิง web push มาหา
                this.token = JSON.stringify(subscription)
              } catch(e) {
                alert(`error: ${e}`);
              }
            }).catch(error => alert("Service Worker registration failed:" + error));
          }else{
            alert(this.$t('คุณยังไม่ได้อนุญาตให้แอพรับ Notification คุณสามารถเปิดได้ใน Settings'));
          }
        });
      }else{
        alert("Service Workers are not supported in this browser.");
      }

    },
    urlBase64ToUint8Array(base64String){
      const padding = "=".repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
          .replace(/-/g, "+")
          .replace(/_/g, "/");
      const rawData = window.atob(base64);
      return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
    },
  },
}
</script>

<template>
  <div>
    <h1>Hello World</h1>
    <v-btn @click="registerWebPushNotification">sub notification</v-btn>
    <v-divider/>
    {{ token }}
  </div>
</template>