<template>
    <!-- กล่องด้านข้าง ที่ไว้แสดงรายละเอียด order -->
    <v-slide-y-transition hide-on-leave>
        <div v-show="expandOrderDetail"
             id="orderInfo"
             ref="orderInfo"
             class="orderInfo expanded">
            <!-- แสดงสินค้าใน order แบบเต็มรูป -->
            <div class="info_content">
                <div class="info_content_title mb-2" :style="showCart?'font-size: 1rem;':'font-size: 0.875rem;'">
                    <template v-if="showCart">
                        <v-btn :href="(isRealMall)?mall.url:shopData.url"
                               key="backToShop"
                               color="primary"
                               class="backButton"
                               text>
                            <v-icon color="primary"
                                    class="mr-2">mdi-chevron-left
                            </v-icon>
                            {{ isDesktop?$t('เลือกซื้อสินค้าเพิ่ม'):$t('เลือกซื้อเพิ่ม') }}
                        </v-btn>
                        <div class="font-weight-bold" :class="isDesktop?'text-h6 mb-0':''">{{ isDesktop?$t('ตะกร้าของฉัน'):$t('ตะกร้า') }}</div>
                        <div class="text-body-2 text-right" :style="isDesktop?'min-width: 160px':'min-width: 100px;'">{{ number_format(cpOrderItemsWithSubItems.length) }} {{ $t('รายการ') }}
                            <span v-if="cpOrderItemsWithSubItems.length !== cpOrderQuantityWithSubItems"
                                  style="padding-left: 3px;">({{ number_format(cpOrderQuantityWithSubItems) }} {{ $t('ชิ้น') }})</span>
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="canEditProductQuantity">
                            <v-btn color="primary"
                                   key="backToCart"
                                   class="backButton"
                                   text
                                   @click="showCart = !showCart">
                                <v-icon color="primary"
                                        class="mr-2">mdi-chevron-left
                                </v-icon>
                                {{ $t('ตะกร้า') }}
                            </v-btn>
                            <!-- @todo นี่คืออะไร ทำไมมองไม่เห็นบน production ทั้งที่ไม่มี v-if -->
                            <!--   มีครับ มันคืออยู่ตรงกลาง                         -->
                            <b>{{ $t('สั่งซื้อ') }} ({{ cpOrderQuantityWithSubItems }})</b>
                        </template>
                        <template v-else>
                            <b>{{ $t('รายการสินค้า') }}</b>
                        </template>
                        <a v-if="orderData.order_status === 'wait_order'"
                           class="primary--text clickable"
                           :href="(isRealMall)?mall.url:shopData.url">
                            <span class="primary--text"
                                  style="">
                                <template v-if="canEditProductQuantity">
                                    {{ $t('เลือกซื้อเพิ่ม') }}
                                </template>
                                <template v-else>
                                    {{ $t('กลับไปร้านค้า') }}
                                </template>
                            </span></a>
                        <v-chip v-else
                                small
                                class="minor text-right">{{ number_format(cpOrderItemsWithSubItems.length) }} {{ $t('รายการ') }}
                            <span v-if="cpOrderItemsWithSubItems.length !== cpOrderQuantityWithSubItems"
                                  style="padding-left: 3px;">({{ number_format(cpOrderQuantityWithSubItems) }} {{ $t('ชิ้น') }})</span>
                        </v-chip>
                    </template>
                </div>
                <slot></slot>
                <v-scroll-y-transition hide-on-leave>
                    <the-order-items></the-order-items>
                </v-scroll-y-transition>
                <div class="info_content_row">
                    <div class="coupon">
                        <div class="just_flex"
                             v-if="canEditCart">
                            <v-text-field v-model="form_coupon_code"
                                          validate-on-blur
                                          :label="$t('กรอกโค้ดส่วนลด (ถ้ามี)')"
                                          outlined
                                          required
                                          hide-details
                                          class="d-inline-block flex-grow-1">
                                <template v-if="myCoupons && myCoupons.length > 0"
                                          v-slot:append>
                                    <v-dialog v-model="couponDialog"
                                              transition="slide-y-transition"
                                              width="400px"
                                              scrollable>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn text
                                                   v-bind="attrs"
                                                   v-on="on">
                                                <v-icon left>
                                                    mdi-ticket-percent-outline
                                                </v-icon>
                                                {{ $t('เลือก') }}
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title>{{ $t('คูปองที่สามารถใช้งานได้') }}</v-card-title>
                                            <v-divider></v-divider>
                                            <v-card-text class="p-0">
                                                <v-list two-line>
                                                    <v-list-item-group v-model="selectedCoupon"
                                                                       color="primary">
                                                        <v-list-item v-for="coupon in myCoupons"
                                                                     class="couponList couponCallAction"
                                                                     :key="'coupon'+ coupon.id"
                                                                     @click="(coupon.errors.length>0)?'':selectExistedCoupon(coupon)">
                                                            <v-list-item-action style="align-self: center">
                                                                <v-radio :class="coupon.errors.length>0?'disabled':''"></v-radio>
                                                            </v-list-item-action>
                                                            <v-list-item-icon class="pr-4"
                                                                              :class="coupon.errors.length>0?'disabled':''">
                                                                <div class="coupon_type">
                                                                    <div class="coupon_card"
                                                                         :class="coupon.config.coupon_style">
                                                                        <div class="coupon_card_detail">
                                                                            <div v-if="coupon.config.discount_type==='discount_price'"
                                                                                 class="genre_price">
                                                                                {{ number_format(coupon.config.discount_value) }}<span class="genre_unit">฿</span>
                                                                            </div>
                                                                            <div v-else-if="coupon.config.discount_type==='discount_percent'"
                                                                                 class="genre_price">
                                                                                {{ number_format(coupon.config.discount_value) }}<span class="genre_unit">%</span>
                                                                            </div>
                                                                            <div v-else-if="coupon.config.discount_type==='free_shipping'"
                                                                                 class="genre_price">
                                                                                Free
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title :class="coupon.errors.length>0?'disabled':''">
                                                                    <div class="coupon_detail">
                                                                        <div v-if="coupon.config.discount_type==='discount_price'"
                                                                             class="name">{{ $t('ส่วนลด') }} {{
                                                                                number_format(coupon.config.discount_value)
                                                                            }} {{ $t('บาท') }}
                                                                        </div>
                                                                        <div v-else-if="coupon.config.discount_type==='discount_percent'"
                                                                             class="name">{{ $t('ส่วนลด') }} {{
                                                                                number_format(coupon.config.discount_value)
                                                                            }} %
                                                                        </div>
                                                                        <div v-else-if="coupon.config.discount_type==='free_shipping'"
                                                                             class="name">{{ $t('ฟรีค่าจัดส่ง') }}
                                                                        </div>
                                                                    </div>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle>
                                                                    {{ coupon.config.name }}
                                                                    <div v-if="coupon.config.end_date_show"
                                                                         class="minor">
                                                                        {{ $t('หมดเขต') }} {{ coupon.config.end_date_show }}
                                                                    </div>
                                                                    <div class="error--text coupon_error"
                                                                         :style="coupon.errors.length>0?'':'display:none;'">
                                                                        <div v-for="error in coupon.errors"
                                                                             :key="'error_pro' + error.key">
                                                                            {{ error_message(error) }} <br>
                                                                            <a target="_blank"
                                                                               :href="coupon.config.url"
                                                                               v-if="error.key === 'COUPON_INVALID_CONDITION'"> {{ $t('ดูเงื่อนไข') }} </a>
                                                                        </div>
                                                                    </div>
                                                                </v-list-item-subtitle>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-card-text>
                                            <v-divider></v-divider>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="gray darken-1"
                                                       text
                                                       @click="couponDialog = false">
                                                    {{ $t('ปิด') }}
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </template>
                            </v-text-field>
                            <v-btn height="56"
                                   large
                                   class="ml-4 my-2 d-inline-block primary couponBtn couponCallAction"
                                   :loading="couponLoading"
                                   :disabled="form_coupon_code == ''"
                                   @click="clickCouponButton"
                                   style="vertical-align: top; font-size: 16px;">
                                <v-icon color="white">mdi-arrow-right</v-icon>
                            </v-btn>
                        </div>
                        <template v-if="appliedCoupons.length > 0">
                            <div class="">
                                <v-chip v-for="coupon in appliedCoupons"
                                        :key="coupon.id"
                                        class="mr-2 mt-2 py-1"
                                        :close="canRemoveCoupon"
                                        color="red"
                                        text-color="white"
                                        @click:close="removeCoupon(coupon)">
                                    <v-icon v-if="!canRemoveCoupon"
                                            small
                                            class="p-0 mr-2">mdi-checkbox-marked-circle
                                    </v-icon>
                                    <b class="small">{{ coupon.code ? coupon.code : (coupon.config ? coupon.config.name : '') }}</b>
                                </v-chip>
                            </div>
                        </template>
                    </div>
                    <div class="price_rows">
                        <div :key="'row'+idx"
                             class="row"
                             v-for="(row,idx) in cpOrderRows">
                            <div class="name">{{ $t(row.name) }}</div>
                            <div class="price"
                                 :class="(row.amount < 0)?'red--text':''">
                                <template v-if="row.type === 'shipping' && !isOrderNoShipping">
                                    <span v-if="isWaitingShippingPrice"
                                          class="red--text">{{ $t('รอร้านค้าคิดค่าส่ง') }}</span>
                                    <span v-else-if="orderData.is_wait_confirm_shipping_price || (status == 'wait_order' && selected_shipping.format == 'custom')"
                                          class="red--text">{{ $t('คิดค่าส่งภายหลัง') }}</span>
                                    <span v-else-if="!hasShippingPrice"
                                          class="minor">{{ $t('ยังไม่รวมค่าส่ง') }}</span>
                                    <span v-else><span class="baht">฿</span>{{ number_format(row.amount, 2) }}</span>
                                </template>
                                <template v-else>
                                    <span v-if="row.amount < 0">- <span class="baht">฿</span>{{ number_format(row.amount * -1, 2) }}</span>
                                    <span v-else><span class="baht">฿</span>{{ number_format(row.amount, 2) }}</span>
                                </template>
                            </div>
                        </div>
                        <div v-if="isRealMall && orderData.price.lnwcash"
                             class="row">
                            <div class="name">ส่วนลดคูปอง TBF</div>
                            <div class="price">
                                - <span class="baht">฿</span>{{ number_format(orderData.price.lnwcash, 2) }}
                            </div>
                        </div>
                        <!--                    <pre>{{ JSON.stringify(my_lnwpoint_obj, null, 2) }}</pre>-->
                        <div class="row"
                             v-if="showLnwPointsRow">
                            <v-dialog v-model="lnwPointDialog"
                                      max-width="400"
                                      persistent
                                      :retain-focus="false">
                                <v-card>
                                    <v-card-title class="headline">
                                        <span>{{ $t('ใช้คะแนนเป็นส่วนลด') }}</span>
                                    </v-card-title>
                                    <v-card-text>{{ $t('คุณมี LnwPoints') }}
                                        <span class="important_data">{{ number_format(my_lnwpoint_obj.available_balance) }}</span> {{ $t('คะแนน') }}
                                        <div class="just_flex_start pt-6">
                                            <v-text-field outlined
                                                          autofocus
                                                          type="number"
                                                          :label="$t('ใช้คะแนน')"
                                                          name="lnwpoints_redeem"
                                                          :hint="'100 ' + $t('คะแนน') + ' = 1 ' + $t('บาท')"
                                                          persistent-hint
                                                          :rules="[lnwPointRules.max]"
                                                          :disabled="!my_lnwpoint_obj.available_balance"
                                                          v-model="lnwPointDialogValue_in_dialog"></v-text-field>
                                            <v-text-field flat
                                                          disabled
                                                          :label="$t('คิดเป็นส่วนลด')"
                                                          name="lnwpoints_redeem_value"
                                                          prefix="฿"
                                                          :value="number_format (lnwPointDialogValue_in_dialog / 100, 2)"
                                                          class="pl-5"></v-text-field>
                                        </div>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text
                                               @click="lnwPointDialog = false">
                                            {{ $t('ปิด') }}
                                        </v-btn>
                                        <template v-if="my_lnwpoint_obj.available_balance">
                                            <v-btn color="primary"
                                                   text
                                                   :loading="pointSubmitting"
                                                   :disabled="(my_lnwpoint_obj.available_balance && (parseFloat(lnwPointDialogValue_in_dialog) > parseFloat(my_lnwpoint_obj.available_balance)))"
                                                   @click="dialogSubmitLnwPoints()">
                                                {{ $t('ตกลง') }}
                                            </v-btn>
                                        </template>
                                        <template v-else>
                                            <v-btn color="secondary"
                                                   text
                                                   @click="lnwPointDialog = false">
                                                {{ $t('ยกเลิก') }}
                                            </v-btn>
                                        </template>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <template v-if="order_lnwpoint">
                                <div class="name">
                                    {{ $t('ใช้') }} {{ number_format(order_lnwpoint) }} {{ $t('คะแนน') }}
                                    <span v-if="canRedeemLnwPoint"
                                          class="primary--text clickable"
                                          @click.stop="openDialogLnwPoints()">
                                        {{ $t('แก้ไข') }}
                                    </span>
                                </div>
                                <div class="price red--text">
                                    - <span class="baht">฿</span>{{ number_format(order_discount_from_lnwpoint, 2) }}
                                </div>
                            </template>
                            <template v-else-if="my_lnwpoint_obj.available_balance !== null">
                                <div class="name">{{ $t('คุณมี LnwPoints') }}
                                    <span class="">{{ number_format(my_lnwpoint_obj.available_balance) }}</span> {{ $t('คะแนน') }}
                                </div>
                                <div class="price">
                                    <div class="primary--text clickable"
                                         @click.stop="openDialogLnwPoints()">
                                        {{ $t('ใช้คะแนน') }}
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="row"
                             v-if="showPointsRow">
                            <v-dialog v-model="shopPointDialog"
                                      max-width="400"
                                      persistent
                                      :retain-focus="false">
                                <v-card>
                                    <v-card-title class="headline">
                                        <span>{{ $t('ใช้คะแนนเป็นส่วนลด') }}</span>
                                    </v-card-title>
                                    <v-card-text>{{ $t('คุณมี Points') }}
                                        <span class="important_data">{{ number_format(useable_shop_point) }}</span> {{ $t('คะแนน') }}
                                        <div class="just_flex_start pt-6">
                                            <v-text-field outlined
                                                          autofocus
                                                          type="number"
                                                          :label="$t('ใช้คะแนน')"
                                                          name="shop_points_redeem"
                                                          persistent-hint
                                                          :rules="[shopPointRules.max]"
                                                          :disabled="!useable_shop_point"
                                                          v-model="shopPointDialogValue_in_dialog"></v-text-field>
                                            <!-- ตอนนี้ยังไม่มีสูตรคำนวณส่งมูลค่า point ที่เหลือมาให้
                                            <v-text-field flat
                                                          disabled
                                                          :label="$t('คิดเป็นส่วนลด')"
                                                          name="lnwpoints_redeem_value"
                                                          prefix="฿"
                                                          :value="number_format (lnwPointDialogValue_in_dialog / 100, 2)"
                                                          class="pl-5"></v-text-field>-->
                                        </div>
                                        <div class="minor">
                                            {{ orderData.shop_point_use_condition }}
                                        </div>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text
                                               @click="shopPointDialog = false">
                                            {{ $t('ปิด') }}
                                        </v-btn>
                                        <template v-if="useable_shop_point">
                                            <v-btn color="primary"
                                                   text
                                                   :loading="pointSubmitting"
                                                   :disabled="(useable_shop_point && (parseFloat(shopPointDialogValue_in_dialog) > parseFloat(useable_shop_point)))"
                                                   @click="dialogSubmitShopPoints()">
                                                {{ $t('ตกลง') }}
                                            </v-btn>
                                        </template>
                                        <template v-else>
                                            <v-btn color="secondary"
                                                   text
                                                   @click="shopPointDialog = false">
                                                {{ $t('ยกเลิก') }}
                                            </v-btn>
                                        </template>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <template v-if="showPointsRow">
                                <template v-if="!order_shop_point">
                                    <div class="name">{{ $t('คุณมี Points') }}
                                        <span class="">{{ number_format(useable_shop_point) }}</span> {{ $t('คะแนน') }}
                                    </div>
                                    <template v-if="canRedeemShopPoint">
                                        <div class="price">
                                            <div class="primary--text clickable"
                                                 @click.stop="openDialogShopPoints()">
                                                {{ $t('ใช้คะแนน') }}
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="isRequiredJoinToRedeemShopPoint">
                                        <div class="price">
                                            <join-dialog :fetchMyOrderList="true">
                                                <template v-slot:activator="{on,attrs}">
                                                    <div v-bind="attrs"
                                                         v-on="on"
                                                         class="primary--text clickable">
                                                        {{ $t('Join เพื่อใช้คะแนน') }}
                                                    </div>
                                                </template>
                                            </join-dialog>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="name">
                                        {{ $t('ใช้') }} {{ number_format(order_shop_point) }} {{ $t('คะแนน') }}
                                        <span v-if="canRedeemShopPoint"
                                              class="primary--text clickable"
                                              @click.stop="openDialogShopPoints()">
                                            {{ $t('แก้ไข') }}
                                        </span>
                                    </div>
                                    <div class="price red--text">
                                        - <span class="baht">฿</span>{{ number_format(order_discount_from_shop_point, 2) }}
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="row net_row">
                            <div class="name">{{ $t('ราคาสุทธิ') }}</div>
                            <div class="price"
                                 :class="(updateNetPrice)?'slideDown':''">
                                <span class="baht">฿</span>{{ cpNetPrice }}
                            </div>
                        </div>
                        <div v-if="orderData.receive_shop_point"
                             class="row point_row">
                            <div class="name">
                                {{ $t('คะแนนที่คุณจะได้รับ') }}
                                <v-tooltip top
                                           max-width="300">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon x-small
                                                v-bind="attrs"
                                                v-on="on">mdi-information-outline
                                        </v-icon>
                                    </template>
                                    {{ $t('คะแนนที่คุณจะได้รับเมื่อการสั่งซื้อและชำระเงินสำเร็จ เพื่อใช้เป็นส่วนลดในการสั่งซื้อครั้งถัดไป') }} *{{ $t('จำเป็นต้องเข้าสู่ระบบ') }}
                                </v-tooltip>
                            </div>
                            <div class="price"
                                 :class="(updateNetPrice)?'slideDown':''">
                                +{{ number_format(orderData.receive_shop_point) }} {{ $t('คะแนน') }}
                            </div>
                        </div>
                        <div class="just_flex">
                            <debug-indicator file-name="src/components/Order/TheOrderDetail.vue:552"
                                             detail="กล่องหมายเหตุถึงร้าน"></debug-indicator>
                            <v-dialog v-if="remarkDialogOrder !== null"
                                      v-model="remarkDialogOrder"
                                      max-width="400"
                                      persistent
                                      :retain-focus="false">
                                <v-card>
                                    <v-card-title class="just_flex">
                                        <div class="headline">{{ remarkText }}</div>
                                        <v-btn icon
                                               class="ml-8"
                                               @click="remarkDialogOrder = false">
                                            <v-icon color="primary">mdi-close</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>{{ $t('กรอกข้อมูลที่ต้องการแจ้งให้เจ้าของร้านทราบ') }}</v-card-text>
                                    <v-card-text class="pb-0">
                                        <v-textarea outlined
                                                    autofocus
                                                    :label="$t('ข้อความถึงร้าน')"
                                                    rows="3"
                                                    v-model="remarkDialogOrderValue_in_dialog"></v-textarea>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary"
                                               text
                                               @click="dialogSubmitOrderRemark()">
                                            {{ $t('ตกลง') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <template v-if="showCart">
                                <v-textarea outlined
                                            :label="remarkText"
                                            rows="3"
                                            class="mt-1"
                                            @blur="dialogSubmitOrderRemark()"
                                            v-model="remarkDialogOrderValue_in_dialog"></v-textarea>
                            </template>
                            <template v-else>
                                <v-slide-y-transition>
                                    <div v-if="remarkDialogOrderValue"
                                         class="remark">{{ remarkDialogOrderValue }}
                                    </div>
                                </v-slide-y-transition>
                                <div class="primary--text">
                                <span class="clickable"
                                      style="font-size: 0.9em;"
                                      @click.stop="openDialogOrder()"
                                      v-if="canEditOrderDetail"
                                      v-text="(!remarkDialogOrderValue)?remarkText:$t('แก้ไข')"></span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div v-if="false && custom_forms.length > 0">
                    <ul>
                        <li v-for="dat in custom_forms"
                            :key="dat.key">
                            {{ dat.text }} {{ custom_datas[dat.key] }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </v-slide-y-transition>
</template>
<script>
import MixinsDisabledIconFocus from "@/mixins/MixinsDisabledIconFocus.vue";
import MixinsFormCouponCode from "@/components/Order/mixins/MixinsFormCouponCode.vue";
import MixinsFormExpandOrderDetail from "@/components/Order/mixins/MixinsFormExpandOrderDetail.vue";
import DebugIndicator from "@/components/DebugTools/DebugIndicator.vue";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {clock, ThaiDateTime_format2} from "@/libraries/DateTime.js";
import Helper from "@/libraries/Helper.js";
import MixinsTheOrderDetailMutual from "@/components/Order/mixins/MixinsTheOrderDetailMutual.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import JoinDialog from "@/components/Order/components/JoinComponent";
import TheOrderItems from "@/components/Order/TheOrderItems.vue";

export default {
    name: "TheOrderDetail",
    mixins: [
        MixinsDisabledIconFocus,
        MixinsFormCouponCode,
        MixinsFormExpandOrderDetail,
        MixinsTheOrderDetailMutual,
        MixinsHelper,
    ],
    data(){
        return {
            couponDialog: false,
            lnwPointDialog: false,
            lnwPointDialogValue: false,
            lnwPointDialogValue_in_dialog: '',
            shopPointDialog: false,
            shopPointDialogValue: false,
            shopPointDialogValue_in_dialog: '',
            pointSubmitting: false,
            couponLoading: false,
            updateNetPrice: false, // for slideDown animation
            selectedCoupon: null,
            remarkDialogOrder: false,
            remarkDialogOrderValue_in_dialog: '',
        };
    },
    components: {
        DebugIndicator,
        JoinDialog,
        TheOrderItems
    },
    computed: {
        ...mapGetters({
            appliedCoupons: 'order/appliedCoupons',
            isProtectedByLnwPay: 'order/isProtectedByLnwPay',
            canAddCoupon: 'order/canAddCoupon',
            canRemoveCoupon: 'order/canRemoveCoupon',
            myCoupons: 'order/myCoupons',
            canEditProductQuantity: 'order/canEditProductQuantity',
            canEditProductDetail: 'order/canEditProductDetail',
            canEditOrderDetail: 'order/canEditOrderDetail',
            canRedeemLnwPoint: 'order/canRedeemLnwPoint',
            isRequiredJoinToRedeemShopPoint: 'order/isRequiredJoinToRedeemShopPoint',
            canRedeemShopPoint: 'order/canRedeemShopPoint',
            receiveShopPoint: 'order/receiveShopPoint',
            custom_forms: 'order/custom_forms',
            custom_datas: 'order/custom_datas',
            mall: 'order/mall',
            isOrderNoShipping: 'order/isOrderNoShipping',
            isRealMall: 'order/isRealMall',
            isWaitingShippingPrice: 'order/isWaitingShippingPrice',
            selected_shipping: 'order/selectedShipping',
        }),
        ...mapState({
            // module: order
            remarkDialogOrderValue: state => state.order.orderData ? state.order.orderData.detail : null,
            status: state => state.order.orderData?.order_status,
            orderData: state => state.order.orderData,
            isAjaxLoading: state => state.order.isAjaxLoading,
            shopData: state => state.order.shopData,
            // module: user
            user: state => state.user.userData,
            // object lnwpoint จาก API lnwpay
            my_lnwpoint_obj: state => {
                return (state.order.orderData.is_user_login && state.lnwpay.point)
                    ? state.lnwpay.point
                    : {
                        available_balance: null,
                        available_money: null,
                        point_value: null,
                    };
            },
            useable_shop_point: state => state.order.orderData.useable_shop_point,
            // order นี้ระบุว่าใช้ lnwpoint ไปเท่าไหร่
            order_lnwpoint: state => state.order.orderData.lnwpoint,
            // order นี้ได้รับส่วนลดจาก lnwpoint กี่บาท
            order_discount_from_lnwpoint: state => state.order.orderData.price?.lnwpoint,

            // order นี้ระบุว่าใช้ point ไปเท่าไหร่
            order_shop_point: state => state.order.orderData.shop_point,
            // order นี้ได้รับส่วนลดจาก point กี่บาท
            order_discount_from_shop_point: state => state.order.orderData.price?.shop_point,
        }),
        showCart: {
            set(value){
                this.$store.commit('order/setForm', {key: 'show_cart', value: value});
            },
            get(){
                return this.$store.state.order.form.show_cart;
            },
        },
        cpOrderRows(){
            if(this.$store.state.order.orderData){
                return this.$store.state.order.orderData.rows;
            }
            return [];
        },
        // cpSummaryWithoutShippingPrice(){
        //     if(this.$store.state.order.orderData){
        //         return '<span class="baht">฿</span>' + Helper.number_format(this.$store.state.order.orderData.price.product, 2);
        //     }
        //     return '<span class="baht">฿</span>-';
        // },
        cpShippingPrice(){
            if(this.$store.state.order.orderData){
                if(this.$store.state.order.orderData.shipping_type_id){
                    return '<span class="baht">฿</span>' + Helper.number_format(this.$store.state.order.orderData.price.shipping, 2);
                }
                return this.$t('ยังไม่ได้คำนวณค่าจัดส่ง');
            }
            return '<span class="baht">฿</span>-';
        },
        lnwPointRules(){
            return {
                max: value => value <= parseFloat(this.my_lnwpoint_obj.available_balance) || this.$t('เกินคะแนนที่คุณมีอยู่'),
            }
        },
        shopPointRules(){
            return {
                max: value => value <= parseFloat(this.useable_shop_point) || this.$t('เกินคะแนนที่คุณมีอยู่'),
            }
        },
        remarkText() {
            if(this.shopData.web_id == 1016629){
                return this.$t('แจ้งร้านสักนิดว่าคุณคือ MWIT รุ่นที่เท่าไร');
            }
            if(this.hasBitKubShoesInCart){
                return this.$t('กรุณาระบุไซส์รองเท้า และ Bitkub Next Wallet');
            }
            return this.$t('เพิ่มข้อความถึงร้านค้า');
        },
        hasWaitingOrder(){
            return 0;
            // ต้องอ่านจาก source อื่น ที่ไม่ใช่ orderOK
            // let totalQuantity = this.orderOK.reduce(function (prev, cur) {
            //     if (cur.status == 'completed') {
            //         return prev;
            //     }
            //     return prev + parseFloat(cur.quantity);
            // }, 0);
            // return totalQuantity;
        },
        canEditCart(){
            return this.canEditProductDetail || this.canEditProductQuantity;
        },
        clock(){
            return clock;
        },
        showLnwPointsRow(){
            return !this.isRealMall &&
                (this.canRedeemLnwPoint || this.order_lnwpoint);
        },
        showPointsRow(){
            return !this.isRealMall &&
                ((this.canRedeemShopPoint || this.isRequiredJoinToRedeemShopPoint) || this.order_shop_point);
        },
    },
    methods: {
        ...mapActions({
            actionChangeProductQuantity: 'order/changeProductQuantity',
            actionRemoveProductFromCart: 'order/removeProductFromCart',
            actionUpdateProductRemark: 'order/updateProductRemark',
            actionUpdateOrderDetail: 'order/updateOrderDetail',
            actionSetRedeemLnwPoint: 'order/setRedeemLnwPoint',
            actionSetRedeemShopPoint: 'order/setRedeemShopPoint',
        }),
        ...mapMutations({}),
        number_format(num, decimal){
            return Helper.number_format(num, decimal);
        },
        ThaiDateTime_format2(datetime){
            return ThaiDateTime_format2(datetime);
        },
        clickCouponButton(){
            this.applyCouponCode();
            this.loader = 'couponLoading';
        },
        selectExistedCoupon(coupon){
            this.applyCouponId(coupon.id)
                .then(() => {
                    this.couponDialog = false;
                });
        },
        openDialogLnwPoints(){
            if(this.checkNoPermissionAndPopup()) return;
            this.lnwPointDialogValue_in_dialog = this.order_lnwpoint;
            this.lnwPointDialog = true;
        },
        openDialogShopPoints(){
            if(this.checkNoPermissionAndPopup()) return;
            this.shopPointDialogValue_in_dialog = this.order_shop_point;
            this.shopPointDialog = true;
        },
        async dialogSubmitLnwPoints(){
            this.pointSubmitting = true;
            let lnwpoint_amount = this.lnwPointDialogValue_in_dialog;
            if(lnwpoint_amount === '')
                lnwpoint_amount = '0';

            if(lnwpoint_amount != this.order_lnwpoint){
                await this.actionSetRedeemLnwPoint({lnwpoint_amount})
            }
            this.pointSubmitting = false;
            this.lnwPointDialog = false;
        },
        async dialogSubmitShopPoints(){
            this.pointSubmitting = true;
            let shop_point_amount = this.shopPointDialogValue_in_dialog;
            if(shop_point_amount === '')
                shop_point_amount = '0';

            if(shop_point_amount != this.order_shop_point){
                await this.actionSetRedeemShopPoint({shop_point_amount})
            }
            this.pointSubmitting = false;
            this.shopPointDialog = false;
        },
        openDialogOrder(){
            if(this.checkNoPermissionAndPopup()) return;
            this.remarkDialogOrderValue_in_dialog = this.remarkDialogOrderValue;
            this.remarkDialogOrder = true;
        },
        dialogSubmitOrderRemark(){
            this.remarkDialogOrder = false;
            if(this.remarkDialogOrderValue_in_dialog != this.remarkDialogOrderValue)
                this.actionUpdateOrderDetail(this.remarkDialogOrderValue_in_dialog);
        },
    },
    mounted(){
        if(this.isDesktop || this.showCart){
            this.expandOrderDetail = true;
        }
    },
    watch: {
        loader(){
            const l = this.loader;
            this[l] = !this[l];
            setTimeout(() => (this[l] = false), 3000);
            this.loader = null;
        },
        cpNetPrice: function (newValue, oldValue){
            // noinspection EqualityComparisonWithCoercionJS
            if(newValue != oldValue){
                this.updateNetPrice = true;
                let that = this;
                setTimeout(function (){
                    that.updateNetPrice = false;
                }, 1000);
            }
        },
    },
}
</script>
<style scoped></style>