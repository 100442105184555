<template>
    <v-menu v-model="noti_popup"
            offset-y
            close-on-click
            :close-on-content-click="false"
            z-index="201"
            :max-height="isDesktop?500:'calc(100vh - 60px)'"
            :min-width="isDesktop?'400':'100%'"
            :max-width="isDesktop?'400':'100%'"
            allow-overflow
            :right="isAppPage"
            :left="!isAppPage"
            :style="isDesktop?'':'left: 0px !important'"
            :content-class="isDesktop?'rounded-xl noti_popup':'rounded-lg noti_popup h-full'"
            transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs"
                   v-on="on"
                   color="primary"
                   icon>
                <v-badge :content="notifications_count"
                         :value="notifications_count"
                         color="red"
                         overlap>
                    <v-icon>
                        mdi-bell-outline
                    </v-icon>
                </v-badge>
            </v-btn>
        </template>
        <v-list :class="blurryClass">
            <div style="height: 0.1px"></div>
            <v-list-item v-if="!notifications">
                <v-list-item-content class="list-empty py-6"
                                     style="justify-content: center; flex-basis: 100%;">
                    <v-skeleton-loader class="mb-2"
                            type="list-item-avatar-three-line"
                    ></v-skeleton-loader>
                    <v-skeleton-loader class="my-2"
                            type="list-item-avatar-three-line"
                    ></v-skeleton-loader>
                    <v-skeleton-loader class="my-2"
                            type="list-item-avatar-three-line"
                    ></v-skeleton-loader>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="notifications?.length === 0 ">
                <v-list-item-content class="list-empty py-6"
                                     style="justify-content: center; flex-basis: 100%;">
                    - No Notifications -
                </v-list-item-content>
            </v-list-item>
            <template  v-else>
                <v-list-item :class="blurryClass" style="position: sticky; top: 0; z-index: 2" class="pt-1">
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold text-h6 align-baseline">
                            {{ $t('รายการแจ้งเตือน') }}
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="mt-3 mb-2">
                        <template v-if="subscribedDeviceTokenID">
                            <v-btn text small href="/app/notifications"><v-icon small style="border-radius: 999px;" class="mr-2">mdi-bell-check-outline</v-icon>{{ $t('รับแจ้งเตือนแล้ว')}}</v-btn>
                        </template>
                        <template v-else>
                            <v-btn class="px-6"
                                   text
                                   small
                                   color="primary"
                                   @click="registerWebPushNotification"
                                   :loading="isSubscribing"
                                   :disabled="!isSupportSW">
                                <v-icon small style="border-radius: 999px;" class="mr-2">mdi-bell-ring-outline</v-icon>{{ $t('รับแจ้งเตือน')}}
                            </v-btn>
                        </template>
                    </v-list-item-action>
                    <v-list-item-action class="mt-3 mb-2">
                        <v-icon @click="noti_popup = false">mdi-close</v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item
                        v-for="notice in notifications"
                        :key="notice.notification_id"
                        link
                        target="_blank"
                        :class="blurryLiteClass"
                        :href="notice.link"
                        class="ma-3 rounded-xl overflow-hidden">
                    <v-list-item-avatar width="50" height="auto" :class="(notice.format == 'shop')?'rounded-circle':'rounded-lg'">
                        <v-img
                                :src="notice.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title style="white-space: normal;">
                            <div class="line-clamp-2" style="font-size: 0.8em;  word-break: break-word" v-html="notice.message"></div>
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 0.7em; justify-content: flex-start">
                            <v-badge v-if="notice.status !== 'read'" color="red" dot inline left></v-badge>
                            <span :title="notice.time">{{ notice.time_diff }}</span>

                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <div v-if="lnwmsg_num < lnwmsg_offset">
                    <div style="height: 4px; width: 100px; margin: 0 auto; background: #999; border-radius: 4px;" class="mb-4"></div>
                </div>
            </template>
            <div style="height: 0.1px"></div>
        </v-list>
    </v-menu>
</template>
<script>
import {mapActions, mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import mixinsNotifications from "@/components/Order/mixins/MixinsNotifications.vue";

export default {
    name: 'NotiPopup',
    data(){
        return {
            is_loading_msg: false,
            noti_popup: false,
        };
    },
    props: {
        isAppPage: {
            type: Boolean,
        }
    },
    mixins: [MixinsHelper, mixinsNotifications],
    computed: {
        ...mapState({
            isFetchedNotifications: state => state.user.called.fetchNotifications,
            isFetchedNotificationsCount: state => state.user.called.fetchNotificationsCount,
            notifications: state => state.user.notifications,
            notifications_count: state => state.user.notifications_count,
            lnwmsg_num: state => state.user.lnwmsg_num,
            lnwmsg_offset: state => state.user.lnwmsg_offset,
        }),
    },
    created(){
    },
    methods: {
        ...mapActions({
            fetchNotifications: 'user/fetchNotifications',
            fetchNotificationsCount: 'user/fetchNotificationsCount',
            readNotifications: 'user/readNotifications',
        }),
    },
    mounted(){
        if(!this.isFetchedNotificationsCount){
            this.fetchNotificationsCount();
        }
    },
    watch: {
        noti_popup(){
            if(!this.isFetchedNotifications){
                this.fetchNotifications();
                let that = this;
                this.waitForElm('.noti_popup').then((elm) => {
                    this.readNotifications();
                    elm.addEventListener("scroll", () => {
                        if(elm.scrollTop + elm.clientHeight + 1 >= elm.scrollHeight){
                            that.fetchNotifications();
                        }
                    });
                });
            }
            if(!this.isFetchedWebPushTokens){
                this.actionFetchWebPushTokens();
            }
        }
    }
};
</script>
<style scoped>
/* Styles go here */
</style>