<template>
    <div v-show="isBrowserNotificationEnabled">
        <div class="just_flex align-center py-2" style="gap: 10px;">
            <div class="needDiv">
                <v-checkbox v-model="browserNotificationCheckbox"
                            :disabled="isSubscribing"
                            @change="changeSubscribe"
                            class="d-inline-block my-2 pt-0"
                            hide-details>
                    <template v-slot:label>
                    <span class="mr-2" style="color: rgba(0, 0, 0, .75)">{{ $t('อัพเดทสถานะทาง') }} <span class="text-capitalize">{{ activeDevice.browser }}</span> <img v-if="activeDevice.browser == 'chrome' || activeDevice.browser == 'safari' || activeDevice.browser == 'firefox'" style="width: 16px; margin-left: 2px" :src="require('@/assets/' + activeDevice.browser + '.svg')"
                                                                                                              alt=""></span>
                    </template>
                </v-checkbox>
            </div>
            <v-tooltip class="d-inline-block"
                       max-width="300"
                       top>
                <template v-slot:activator="{ on }">
                    <div v-on="on"
                         class=" text-no-wrap">
                        <span v-if="false && isDesktop"
                              class="text--secondary text-caption mr-2">{{ $t('นี่คืออะไร') }}</span>
                        <v-icon dense>
                            mdi-help-circle-outline
                        </v-icon>
                    </div>
                </template>
                {{ $t('อัพเดทสถานะรายการสั่งซื้อนี้ (เช่น ร้านค้าจัดส่งสินค้าแล้ว) โดยระบบจะส่งข้อความสถานะให้คุณอัตโนมัติผ่านทาง') }} Browser
            </v-tooltip>
        </div>
        <v-divider></v-divider>
    </div>
</template>
<script>
import {VDialog, VBottomSheet} from 'vuetify/lib';
import DebugIndicator from "@/components/DebugTools/DebugIndicator";
import {mapActions, mapGetters, mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import MixinsNotifications from "@/components/Order/mixins/MixinsNotifications.vue";

export default {
    name: "browserNotification",
    props: {},
    data(){
        return {
            checkboxKey: 0,
            browserNotificationCheckbox: false,
        }
    },
    mixins: [
        MixinsHelper, MixinsNotifications
    ],
    components: {
        DebugIndicator,
        VDialog,
        VBottomSheet
    },
    computed: {
        ...mapGetters({
            cpFlagIsLogin: 'user/isLogin',
            isBrowserNotificationEnabled: 'order/isBrowserNotificationEnabled',
        }),
        ...mapState({
            orderData: state => state.order.orderData,
            shopData: state => state.order.shopData,
            userData: state => state.user.userData,
            activeDevice: state => state.user.activeDevice,
        }),
    },
    methods: {
        ...mapActions({
            actionFetchWebPushTokens: "user/fetchWebPushTokens",
        }),

        async changeSubscribe() {
            if(this.subscribedDeviceTokenID){
                this.isSubscribing = true;
                await this.unsubscribeWebPushNotification();
            }else{
                this.isSubscribing = true;
                await this.registerWebPushNotification();
            }
            this.isSubscribing = false;

        },
        updateCheckbox() {
            setTimeout(()=>{
                this.browserNotificationCheckbox = !!this.subscribedDeviceTokenID;
            }, 100);
        }
    },
    async mounted(){
        if(!this.isFetchedWebPushTokens){
            await this.actionFetchWebPushTokens();
        }
        this.updateCheckbox();
    },
}
</script>