<template>
    <v-container>
        <v-row>
            <v-col cols="12"
                   md="8">
                <v-card class="pa-4"
                        :class="this.$vuetify.theme.dark?'grey darken-3':'grey lighten-5'"
                        elevation="24">
                    <v-card-title>
                        {{ $t('รับการแจ้งเตือน') }}
                    </v-card-title>
                    <v-card-text>
                        <div v-if="subscribedDeviceTokenID">
                            <v-chip class="py-4 px-4"
                                    large
                                    style="border-radius: 10px !important;">
                                <div class="just_flex"
                                     :class="{ 'flex_wrap' : !isDesktop}"
                                     style="gap: 10px; padding-right: 10px;">
                                    <div style="width: 60px"
                                         :style="isDesktop?'max-width: 150px':'max-width: 100px'">
                                        <v-img placeholder
                                               :src="require('@/assets/sticker/receive_notify.png')"></v-img>
                                    </div>
                                    <div>
                                        <span style="line-height: normal">{{ $t('คุณได้สมัครรับการแจ้งเตือนสำหรับอุปกรณ์นี้แล้ว') }}</span>
                                        <div v-for="token in web_push_tokens"
                                             style="margin-top: 5px;"
                                             :key="token.device_token_id">
                                            <v-icon color="success"
                                                    x-small
                                                    class="mr-2">mdi-check-bold
                                            </v-icon>
                                            <span class="minor" :style="isDesktop?'':'font-size: 0.6em;'">Device ID: {{ token.device_token_id }} ({{ token._created_time }})</span>
                                        </div>
                                    </div>
                                </div>
                            </v-chip>
                        </div>
                        <div v-else>{{ $t('คุณยังไม่ได้สมัครรับการแจ้งเตือนบนอุปกรณ์นี้') }}</div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="!subscribedDeviceTokenID"
                               class="px-6"
                               large
                               color="primary"
                               @click="registerWebPushNotification"
                               :loading="isSubscribing"
                               :disabled="!isSupportSW">
                            <v-icon class="mr-2">mdi-bell-ring</v-icon>
                            {{ $t('สมัครรับการแจ้งเตือน') }}
                        </v-btn>
                        <template v-else>
                            <v-btn color="primary"
                                   style="border-radius: 4px;"
                                   text
                                   @click="unsubscribeWebPushNotification"
                                   :disabled="!isSupportSW">
                                {{ $t('ยกเลิกการแจ้งเตือน') }}
                            </v-btn>
                            <v-btn v-if="web_push_tokens?.length > 1"
                                   color="error"
                                   style="border-radius: 4px;"
                                   text
                                   @click="unsubscribeWebPushNotification(true)"
                                   :disabled="!isSupportSW">
                                {{ $t('ยกเลิกทั้งหมด') }}
                            </v-btn>
                        </template>
                    </v-card-actions>
                    <v-card-text v-if="!isSupportSW">
                        <v-alert type="warning">
                            {{ $t('Browser ของคุณไม่รองรับการรับ Notification กรุณา Add to Home Screen เพื่อรับการแจ้งเตือน') }}
                        </v-alert>
<!--                        <v-dialog-->
<!--                            v-model="dialog"-->
<!--                            width="500"-->
<!--                        >-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                                <v-btn class="px-6"-->
<!--                                       large-->
<!--                                       v-bind="attrs"-->
<!--                                       v-on="on"-->
<!--                                       color="primary"-->
<!--                                       @click="showAddToHomeScreenModal">-->
<!--                                    {{ $t('Add To Home Screen') }}-->
<!--                                </v-btn>-->
<!--                            </template>-->

<!--                            <v-card>-->
<!--                                <v-card-title class="text-h5 grey lighten-2">-->
<!--                                    Privacy Policy-->
<!--                                </v-card-title>-->

<!--                                <v-card-text>-->
<!--                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.-->
<!--                                </v-card-text>-->

<!--                                <v-divider></v-divider>-->

<!--                                <v-card-actions>-->
<!--                                    <v-spacer></v-spacer>-->
<!--                                    <v-btn-->
<!--                                        color="primary"-->
<!--                                        text-->
<!--                                        @click="dialog = false"-->
<!--                                    >-->
<!--                                        I accept-->
<!--                                    </v-btn>-->
<!--                                </v-card-actions>-->
<!--                            </v-card>-->
<!--                        </v-dialog>-->

                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12"
                   md="4">
                <v-card class="pa-4"
                        :style="isDesktop?'height: 100%':''"
                        :class="this.$vuetify.theme.dark?'grey darken-3':'grey lighten-5'"
                        elevation="24">
                    <v-card-title>
                        {{ $t('ปกป้องความเป็นส่วนตัว') }}
                    </v-card-title>
                    <v-card-text>
                        <p>{{ $t('ระบบจะส่งการแจ้งเตือนเฉพาะการอัพเดทสถานะออเดอร์เพียงเท่านั้น นั่นหมายถึงจะไม่มีการส่งข้อความโปรโมชั่นใดๆ ถึงคุณ') }}</p>
                        <a href="https://lnw.me/account/pdpa/privacy"
                           target="_blank"
                           style="text-decoration: none;"> Lnw.me - Privacy Policy
                            <v-icon small
                                    color="primary">mdi-shield-account
                            </v-icon>
                            <div class="text-caption grey--text">{{ $t('นโยบายคุ้มครองข้อมูลส่วนบุคคล') }}</div>
                        </a>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- การแจ้งเตือนผ่านเว็บไซต์ Add to Home Screen (เพื่อให้เป็น PWA) -->
        <v-row id="addToHomeScreen">
            <v-col cols="12">
                <v-card class="pa-4"
                        :class="this.$vuetify.theme.dark?'grey darken-3':'grey lighten-5'"
                        elevation="24">
                    <v-card-title>
                        Add to Home Screen
                    </v-card-title>
                    <v-card-text>
                        <p>{{ $t('เพิ่มทางลัดสำหรับเข้า Lnw.me ในลักษณะที่เหมือนเป็น App เพื่อรับ Notification แจ้งอัพเดทสถานะออเดอร์') }}</p>
                        <v-divider class="my-4"></v-divider>
                        <div class="just_flex align-items-start"
                             :class="isDesktop?'':'flex-wrap'"
                             style="gap: 2rem;">
                            <template v-if="computedSteps.length > 0">
                                <div style="flex-grow: 1;">
                                    <div class="font-weight-bold mb-4">{{ $t('ขั้นตอนการเพิ่มแอพ') }} Lnw.me
                                    </div>
                                    <div :class="(isDesktop)?'d-inline-block':''"
                                         :style="isDesktop?'max-width: 450px':'max-width: 100%'">
                                        <!-- Loop through steps -->
                                        <div>
                                            <v-card v-for="(step, index) in computedSteps"
                                                    outlined
                                                    :key="index"
                                                    class="py-3 px-3 d-block rounded-lg"
                                                    :class="index === (computedSteps.length - 1)?'mb-0':'mb-2'"
                                                    large>
                                                <v-chip class="py-1 text-nowrap"
                                                        :class="isDesktop?'mr-4':'mr-3'"
                                                        color="primary"
                                                        small>
                                                    <b>STEP {{ index + 1 }}</b>
                                                </v-chip>
                                                <div v-if="isDesktop && !step.after"
                                                     class="pr-1 d-inline-block">
                                                    {{ $t('เลือก') }} "{{ step.text }}"
                                                </div>
                                                <span v-else>{{ step.text }}</span>
                                                <v-icon v-if="step.icon != 'logo'"
                                                        style="float: right;"
                                                        class="mr-2 ml-4">
                                                    {{ step.icon }}
                                                </v-icon>
                                                <img v-else
                                                     style="margin: 0 10px; border-radius: 0; float: right;"
                                                     width="20"
                                                     src="@/assets/logo.png"/>
                                            </v-card>
                                            <template v-if="!subscribedDeviceTokenID">
                                                <v-divider class="my-4"></v-divider>
                                                {{ $t('จากนั้นให้เปิดแอพ Lnw.me แล้วเลือกเมนู Notification เพื่อกดรับการแจ้งเตือนอีกครั้ง') }}
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <template v-if="activeDevice.os == 'macos'">
                                        <img class="rounded-lg mt-4"
                                             style="width: 400px; max-width: 100%;"
                                             src="@/assets/lnwme-macos.png"/>
                                        <div class="text-caption my-2">{{ $t('ตัวอย่างแอพ Lnw.me บน') }} macOS</div>
                                    </template>
                                    <template v-else-if="activeDevice.os == 'ios'">
                                        <div class="text-caption mb-2">{{ $t('ตัวอย่างแอพ Lnw.me บน') }} iOS</div>
                                        <img class="rounded-lg"
                                             style="width: 300px; max-width: 100%;"
                                             src="@/assets/lnwme-ios-full.png"/>
                                    </template>
                                </div>
                            </template>
                            <v-alert v-else
                                     type="warning">
                                {{ $t('Browser ของคุณไม่รองรับการ Add to Home Screen กรุณาใช้งานผ่าน Chrome หรือ Safari') }}
                            </v-alert>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import mixinsHelper from "@/components/Order/mixins/MixinsHelper.vue";
import mixinsNotifications from "@/components/Order/mixins/MixinsNotifications.vue";

export default {
    name: "NotiPage",
    mixins: [mixinsHelper, mixinsNotifications],
    data(){
        return {
            addToHomeScreenModal: false
        };
    },
    methods: {
        showAddToHomeScreenModal() {
            this.addToHomeScreenModal = !this.addToHomeScreenModal;
        }
    },
    computed: {},
    mounted(){
        if(!this.isFetchedWebPushTokens){
            this.actionFetchWebPushTokens();
        }
    }
};
</script>