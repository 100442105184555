<template>
    <LnwmeLayout :appDrawer="appDrawer"
                 @force-reload="forceReloadServiceWorker"
                 :isAppPage="true">
        <template #toolbar>
            <v-app-bar-nav-icon v-if="!isDesktop">
                <v-icon @click.stop="appDrawer = !appDrawer">
                    mdi-menu
                </v-icon>
            </v-app-bar-nav-icon>
            <v-toolbar-title style="padding-left: 10px">
                <b>
                    <template v-if="isDesktop || currentTitle == 'Home'">Lnw.me - </template>{{ currentTitle }}</b>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="isPwaApp"
                   icon
                   @click="forceReloadServiceWorker">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
        </template>
        <template #drawer>
            <v-navigation-drawer v-model="appDrawer"
                                 app
                                 left
                                 :permanent="isDesktop"
                                 :width="appDrawer ? 320 : 0"
                                 class=""
                                 :class="$vuetify.theme.dark?'':'bg-lnwme'"
                                 style="height: 100%; overflow: auto; z-index: 200; box-sizing: border-box;">
                <div class="d-flex flex-column h-100">
                    <v-list class="pt-5"
                            elevation="0">
                        <v-list-item v-if="false">
                            <img class="my-4"
                                 style="height: 16px;"
                                 src="@/assets/lnw_logo.png"/>
                        </v-list-item>
                        <v-list-item class="pt-2 px-2">
                            <v-autocomplete v-model="searchQuery"
                                            :items="favShops"
                                            :placeholder="$t('ค้นหาร้านโปรด')"
                                            prepend-inner-icon="mdi-magnify"
                                            clearable
                                            outlined
                                            dense
                                            item-text="shopname"
                                            item-value="webname"
                                            class="search-shop"
                                            style="max-width: 100%;"
                                            menu-props="{ maxWidth: '274px' }"
                                            :filter="(item, queryText) => {
    const text = `${item.shopname} ${item.webname}`.toLowerCase();
    return text.includes(queryText.toLowerCase());
  }">
                                <template v-if="favShops !== null"
                                          v-slot:item="{ item }">
                                    <v-list-item style="max-width: 274px;"
                                                 two-line
                                                 dense
                                                 link
                                                 :href="'/shop/' + item.webname"
                                                 class="pl-6 pr-0 rounded-0 text-decoration-none">
                                        <v-list-item-action>
                                            <v-img width="40"
                                                   :aspect-ratio="1"
                                                   class="rounded-lg"
                                                   :src="item.favicon ? item.favicon : item.avatar"></v-img>
                                        </v-list-item-action>
                                        <v-list-item-content class="pr-4">
                                            <v-list-item-title style="line-height: 1.25rem; margin-bottom: 0;">
                                                <b class="primary--text">{{ item.shopname }}</b>
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 0.7em; overflow: hidden;"
                                                                  class="line-clamp-1">
                                                {{ item.url }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-autocomplete>
                        </v-list-item>
                        <v-list-item class="mx-2"
                                     link
                                     :to="'/app/'+ item.key"
                                     v-for="item in appMenuList"
                                     :key="item.key">
                            <v-list-item-icon>
                                <v-icon color="primary"
                                        v-text="item.icon + '-outline'"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.key == 'home' ? 'Lnw.me - ' : '' }} {{ item.label }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <div class="flex-grow-1"></div>
                    <v-divider class="my-4"></v-divider>
                    <v-list elevation="0"
                            class="pb-5">
                        <v-list-item class="mx-2"
                                     link
                                     :to="'/app/' + item.key"
                                     v-for="item in appMenuListBottom"
                                     :key="item.key">
                            <v-list-item-icon>
                                <v-icon color="primary"
                                        v-text="item.icon  + '-outline'"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.label }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action-text v-if="item.chip">
                                <v-chip class="px-3" color="primary" style="cursor: pointer; font-weight: bold !important;" x-small v-text="item.chip"></v-chip>
                            </v-list-item-action-text>
                        </v-list-item>
                    </v-list>
                </div>
            </v-navigation-drawer>
        </template>
        <template #bottom-navigation
                  v-if="!isDesktop">
            <v-bottom-navigation style="justify-content: space-between; box-sizing: border-box"
                                 fixed
                                 :height="isPwaApp?80:60"
                                 color="primary"
                                 grow>
                <v-btn v-for="item in appMenuListMobile"
                       :key="item.key"
                       :to="'/app/'+ item.key">
                    <div :class="isPwaApp?'mb-3':''"
                         style="font-size: 0.8em;">{{ item.label }}
                    </div>
                    <v-icon class="mb-1"
                            v-text="item.icon + (isDesktop?'-outline':'')"></v-icon>
                </v-btn>
            </v-bottom-navigation>
        </template>
        <template #default>
            <router-view/>
        </template>
    </LnwmeLayout>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import LnwmeLayout from "@/views/layouts/LnwmeLayout"

export default {
    name: "ProgressiveWebApp",
    components: {LnwmeLayout},
    mixins: [MixinsHelper],
    data(){
        return {
            pageReady: false,
            appDrawer: this.isDesktop ? true : false,
            searchQuery: '',
        }
    },
    computed: {
        ...mapState({
            favShops: state => state.user.favShops,
        }),
        ...mapGetters({
            isLogin: "user/isLogin",
        }),
        appMenuListMobile(){
            return [
                ...this.appMenuList,
                this.appMenuListBottom.find(item => item.key === 'notifications')
            ];
        },
        currentTitle(){
            return this.$route.meta.title || 'Home';
        },

        appMenuList(){
            return [
                {key: 'home', icon: 'mdi-account-circle', label: this.$t('หน้าหลัก')},
                {key: 'favourite', icon: 'mdi-heart', label: this.$t('ร้านโปรด')},
                {key: 'shops', icon: 'mdi-store', label: this.$t('ร้านของฉัน')},
                {key: 'highlight', icon: 'mdi-star', label: this.$t('ไฮไลท์')}
            ];
        },
        appMenuListBottom(){
            return [
                {
                    key: 'notifications',
                    icon: 'mdi-bell-plus',
                    label: this.isDesktop ? this.$t('รับการแจ้งเตือน') : this.$t('รับแจ้งเตือน')
                },
                {
                    key: 'affiliate',
                    icon: 'mdi-bullhorn-variant',
                    label: this.$t('แนะนำเพื่อน'),
                    chip: this.$t('รับเลย 20%')
                },
                {key: 'account', icon: 'mdi-cog', label: this.$t('ตั้งค่าบัญชี')},
            ];
        },
    },
    methods: {
        ...mapActions({
            logout: "user/logout",
            initUserData: 'user/initUserData',
            fetchFavShops: 'user/fetchFavShops',
        }),
        forceReloadServiceWorker(){
            if('serviceWorker' in navigator){
                navigator.serviceWorker.getRegistration().then((registration) => {
                    if(registration){
                        registration.unregister().then(() => {
                            window.location.reload();
                        });
                    }
                });
            }else{
                window.location.reload();
            }
        }
    },
    beforeDestroy(){
        // Remove the event listener when the component is destroyed
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.checkSystemTheme);
    },
    watch: {
        isLogin (newValue){
            if(newValue){
                if(this.favShops == null){
                    this.fetchFavShops();
                }
            }
        }
    },
    mounted(){
        // this.initUserData()
        this.setMetaTag();
    },
};
</script>