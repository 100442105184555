<template>
<!--    component นี้ใข้เฉพาะ Order.vue เท่านั้น -->
    <div id="login-wrapper">
        <div class="step">
            <div class="header"
                 ref="header">
                <a v-if="shopData"
                   class="logo"
                   :href="shopData.url"
                   target="_blank"> <img alt=""
                                         :src="shopData.avatar"/> <span>
                                {{ shopData.name }}
                </span> </a>

                <template v-if="gointer_enabled">
                    <div class="flag-container"
                         @click="toggleLang()">
                        <div class="flag"
                             :class="$i18n.locale + '-flag'"></div>
                        <span v-if="isDesktop" class="text-uppercase">{{ $i18n.locale }}</span>
                    </div>
                </template>
            </div>
            <div class="needDiv">
                <div class="loginRequired lnwme-container pt-4 pb-8">
                    <div class="stepContent buyerInfo">
                        <v-form @submit.prevent.stop="login"
                                class="mt-10">
                            <template>
                                <div v-if="existingOrderOwner" class="profile">
                                    <v-avatar size="64">
                                        <img alt=""
                                             :src="existingOrderOwner.avatar"/>
                                    </v-avatar>
                                    <div>{{ existingOrderOwner.user_email ? existingOrderOwner.user_email : existingOrderOwner.contact_email }}</div>
                                    <div>{{ existingOrderOwner.user_mobile ? existingOrderOwner.user_mobile : existingOrderOwner.contact_mobile }}</div>
                                </div>
                                <template v-if="twoFactorToken">
                                    <v-form @submit.prevent.stop="submit2faOtp()">
                                        <div>
                                            <div class="my-5">
                                                <div class="font-weight-bold">{{ $t('การยืนยัน 2FA') }}</div>
                                                <div class="text-body-2 my-2">{{ $t('รับรหัสยืนยันด้วย Authenticator App') }}</div>
                                            </div>
                                            <v-text-field v-model="twoFactorCode"
                                                          :label="$t('OTP 6 หลัก')"
                                                          :autocomplete="false"
                                                          :maxlength="6"
                                                          class="mb-6"
                                                          hide-details="auto"
                                                          validate-on-blur
                                                          :rules="[v => !!v || $t('กรุณากรอก OTP ด้วยนะคะ'), v => (v && (v.length === 6)) || this.$t('รูปแบบไม่ถูกต้อง')]"
                                                          pattern="\d*"
                                                          outlined>
                                                <template v-slot:message="{ message }">
                                                    <span v-html="message"></span>
                                                </template>
                                            </v-text-field>
                                        </div>
                                        <div>
                                            <div>
                                                <v-btn x-large
                                                       block
                                                       depressed
                                                       type="submit"
                                                       color="primary">{{ $t('ยืนยัน') }}
                                                </v-btn>
                                            </div>
                                        </div>
                                        <div class="minor mt-6">
                                            {{ $t('ทำไมคุณถึงต้องยืนยัน ? เนื่องจากคุณได้เปิดระบบยืนยันตัวตนแบบ 2FA (Two-Factor Authentication) ไว้เพื่อความปลอดภัยของบัญชี กรุณากรอก OTP ที่ได้รับจาก Authenticator App ของคุณ') }}
                                        </div>
                                    </v-form>
                                </template>
                                <template v-else>
                                    <template v-if="existingOrderOwner">
                                        <div v-if="existingOrderOwner" class="just_flex my-5">
                                            <div class="d-inline-block">{{ $t('เข้าสู่ระบบด้วยบัญชีข้างต้น') }}
                                                <v-tooltip bottom
                                                           max-width="300">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <div v-bind="attrs"
                                                             v-on="on"
                                                             class="d-inline-block"
                                                             style="vertical-align: top">
                                                            <v-icon class="mr-1"
                                                                    color="primary"
                                                                    small>
                                                                mdi-help-circle-outline
                                                            </v-icon>
                                                        </div>
                                                    </template>
                                                    {{ $t('เนื่องจากบิลนี้ได้มีการเข้าสู่ระบบและผูกบัญชีไว้เรียบร้อยแล้ว ซึ่งจะมีข้อมูลส่วนบุคคลต่างๆ ที่ในขณะนี้คุณเข้ามาในฐานะบุคคลทั่วไป จึงไม่สามารถเข้าดูรายละเอียดได้ ดังนั้นคุณจำเป็นต้องยืนยันตัวตนด้วยการเข้าสู่ระบบอีกครั้ง') }}
                                                </v-tooltip>
                                            </div>
                                            <v-btn text
                                                   color="primary"
                                                   :href="accountUrl('forgot_password')">{{ $t('ลืมรหัสผ่าน') }}
                                            </v-btn>
                                        </div>
                                    </template>
                                    <v-text-field v-model="password"
                                                  ref="password"
                                                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                  :type="showPassword ? 'text' : 'password'"
                                                  :label="$t('รหัสผ่าน')"
                                                  @click:append="showPassword = !showPassword"
                                                  required
                                                  validate-on-blur
                                                  v-disabled-icon-focus
                                                  autocomplete="off"
                                                  outlined></v-text-field>
                                    <div>
                                        <v-btn x-large
                                               block
                                               depressed
                                               type="submit"
                                               :loading="isLoggingIn"
                                               color="primary">{{ $t('เข้าสู่ระบบ') }}
                                        </v-btn>
                                    </div>
                                    <div class="social_login_container mt-8 flex_start">
                                    <div class="social_login_text">
<!--                                        <template v-if="$i18n.locale == 'th'">{{ $t('หรือ') }}</template>-->
                                        {{ $t('เข้าสู่ระบบด้วย') }}
                                    </div>
                                    <div class="social_login">
                                        <div class="login_button">
                                            <div class="facebook_login">
                                                <button type="button"
                                                        class="button button-facebook"
                                                        @click="SocialLogin_via_Facebook(true)">
                                                    <span class="logo"></span><span class="text">Facebook</span>
                                                </button>
                                            </div>
                                            <div class="line_login">
                                                <button type="button"
                                                        class="button button-line"
                                                        @click="SocialLogin_via_Line(true)">
                                                    <span class="logo"></span><span class="text">LINE</span></button>
                                            </div>
                                            <div class="google_login">
                                                <button type="button"
                                                        class="button button-google"
                                                        @click="SocialLogin_via_Google(true)">
                                                    <span class="logo"></span><span class="text">Google</span></button>
                                            </div>
                                            <div class="apple_login">
                                                <button type="button"
                                                        class="button button-apple"
                                                        @click="SocialLogin_via_Apple(true)">
                                                    <span class="logo"></span><span class="text">Apple</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </template>
                            </template>
                        </v-form>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <footer-component></footer-component>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import MixinsSocialLoginMethods from "@/mixins/MixinsSocialLoginMethods.vue";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import FooterComponent from "@/components/Order/components/FooterComponent";
import SiteUrl from "@/libraries/SiteUrl.js";


export default {
    name: "TheLoginRequired",
    components: {
        FooterComponent,
    },
    data() {
        return {
            password: '',
            showPassword: false,
            isLoggingIn : false,
            twoFactorToken: "",
            twoFactorCode: "",
        };
    },
    computed: {
        ...mapGetters({
            gointer_enabled: 'order/gointer',
        }),
        ...mapState({
            existingOrderOwner: state => state.order.existingOrderOwner,
            shopData: state => state.order.shopData,
        })
    },
    mixins: [MixinsSocialLoginMethods,MixinsHelper],
    methods: {
        ...mapActions({
            authenticateByUsernamePassword: 'user/authenticateByUsernamePassword',
            updateStepMetasOpen: 'order/updateStepMetasOpen',
            fetchOrder: 'order/fetchOrder',
            actionVerifyOtp: 'user/verifyOtp',
        }),
        async afterAuthenticate() {
            await this.fetchOrder();
        },
        async login() {
            this.isLoggingIn = true;
            const success = await this.authenticateByUsernamePassword({
                encoded_user_id: this.existingOrderOwner.encoded_user_id,
                password: this.password,
            });

            if (success === true) {
                await this.afterAuthenticate();
            } else if (success && typeof success === "object" && success.hasOwnProperty('twoFactorToken')) {
                this.twoFactorToken = success.twoFactorToken;
            }
            this.isLoggingIn = false;
        },
        async submit2faOtp() {
            const success = await this.actionVerifyOtp({
                twoFactorToken: this.twoFactorToken,
                twoFactorCode: this.twoFactorCode,
            })
            if (success) {
                await this.afterAuthenticate()
            }else{
                this.twoFactorCode = null;
            }
        },
        accountUrl(uri) {
            return SiteUrl.account(uri);
        },
    }
}
</script>
<style scoped></style>