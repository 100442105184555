<template>
    <div style="margin-left: 25px;">
        <div v-if="paymentMethod.icons">
            <div v-for="(icon_src, index) in paymentMethod.icons"
                 :key="icon_src + index"
                 style="display: inline;">
                <img v-if="!areAllItemsSame || !index" :src="publicURL(icon_src)"/>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProviderIcons",
        props: {
            paymentMethod: {
                required: true,
                type: Object,
            }
        },
        computed: {
            areAllItemsSame() {
                return this.paymentMethod?.icons.every(item => item === this.paymentMethod.icons[0]);
            }
        },
        methods: {
            publicURL(icon_src) {
                if(process.env.NODE_ENV === 'development'){
                    return icon_src.replace(/^.+(\/system\/application\/modules\/lnwshop\/_images\/.+)$/, 'https://www.lnwshop.com$1');
                }
                return icon_src;
            }
        }
    }
</script>

<style scoped>

</style>