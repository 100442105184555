import Axios from "@/libraries/Axios";
import ApiUrl from "../../libraries/ApiUrl";
import {fbq} from "@/packages/lnw-vue-pixel/install.js";
import store from "@/store";
import {clearAccessToken, getAccessToken, setAccessToken} from "@/libraries/AccessTokenHandler";

const SET_SEARCH_ADDRESSES_RESULT = 'SET_SEARCH_ADDRESSES_RESULT';
const CLEAR_PAGINATED_METADATA = 'CLEAR_PAGINATED_METADATA';

const valid_address_type = type => ['receiverAddresses', 'taxAddresses', 'dealerReceiverAddresses', 'senderAddresses'].includes(type);
const default_paginated_limit = 10;

const SET_CALLED = 'SET_CALLED';
const SET_LINE_NOTIFY_TOKENS = 'SET_LINE_NOTIFY_TOKENS';
const SET_WEB_PUSH_TOKENS = 'SET_WEB_PUSH_TOKENS';
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
const SET_NOTIFICATIONS_COUNT = 'SET_NOTIFICATIONS_COUNT';
const SET_IS_LOADING_MSG = 'SET_IS_LOADING_MSG';
const SET_LNWMSG_NUM = 'SET_LNWMSG_NUM';
const SET_LNWMSG_OFFSET = 'SET_LNWMSG_OFFSET';

var is_img_lnwfile = function(path){
    if(path.match(/^(http(s)?:)?\/\/(file\.lnw\.co\.th|[a-z]+\.lnw(file|pic)\.com)/)){
        return true;
    }
    return false;
}
var lnwfile_fullsize = function(url){
    if(is_img_lnwfile(url)){
        url = url.replace(/^http:/,'https:');
        if(url.match(/\/(_\/[a-z]+\/)?_raw\//)){
            return url.replace(/\/(_\/[a-z]+\/)?_raw\//,'/').replace(/\/([a-z0-9]+)\/([a-z0-9]+)\/([a-z0-9]+)\.([a-z]+)$/, "/$1$2$3.$4");
        }else if(url.match(/\/(_\/[a-z]+\/)?_(jpg_(resize|fill|stretch|fit)|fill|stretch|fit|resize)(_images)?\/[0-9]+\/[0-9]+\//)){
            return url.replace(/\/(_\/[a-z]+\/)?_(jpg_(resize|fill|stretch|fit)|fill|stretch|fit|resize)(_images)?\/[0-9]+\/[0-9]+\//, '/').replace(/\/([a-z0-9]+)\/([a-z0-9]+)\/([a-z0-9]+)\.([a-z]+)$/, "/$1$2$3.$4");
        }else if(url.indexOf('?') != -1){
            return url.substring(0, url.indexOf('?'));
        }
    }
    return url;
}
var src_mini = function(src,w,h,resize_type) {
    if(resize_type == null || typeof resize_type == 'undefined')
        resize_type = 'resize';
    var protocol = 'https';
    var prefix = '_'+resize_type+'/'+w+'/'+h+'/';
    //var mmm = src.match(/^(?<protocol>https?):\/\/(?<host>(?<dir>[a-z]+)\.lnwfile\.com)\/(?<a>[0-9a-z]{2})(?<b>[0-9a-z]{2})(?<c>[0-9a-z]{2,3})\.(?<ext>jpg|gif|png)$/);
    var mmm = src.match(/^(https?):\/\/(([a-z]+)\.lnwfile\.com)\/([0-9a-z]{2})([0-9a-z]{2})([0-9a-z]{2,3})\.(jpg|gif|png)$/);
    if(mmm) {
        //var match = mmm.groups;
        var match = {
            protocol: mmm[1],
            host: mmm[2],
            dir: mmm[3],
            a: mmm[4],
            b: mmm[5],
            c: mmm[6],
            ext: mmm[7],
        };
        var ext = match.ext;
        //var mmmm = resize_type.match(/^(?<ext>jpg|webp)_/);
        var mmmm = resize_type.match(/^(jpg|webp)_/);
        if(mmmm) {
            //ext = mmmm.groups.ext;
            ext = mmmm[1];
        }
        return protocol+'://'+match.host+'/_/'+match.dir+'/'+prefix+match.a+'/'+match.b+'/'+match.c+'.'+ext;
    }
    //var mmm = src.match(/^(?<protocol>https?):\/\/(?<host>(.*)file\.[^/]*)\/(?<a>[0-9a-z]{2})(?<b>[0-9a-z]{2})(?<c>[0-9a-z]{2,3})\.(?<ext>jpg|gif|png)$/);
    mmm = src.match(/^(https?):\/\/((.*)file\.[^/]*)\/([0-9a-z]{2})([0-9a-z]{2})([0-9a-z]{2,3})\.(jpg|gif|png)$/);
    if(mmm) {
        //var match = mmm.groups;
        match = {
            protocol: mmm[1],
            host: mmm[2],
            dir: mmm[3],
            a: mmm[4],
            b: mmm[5],
            c: mmm[6],
            ext: mmm[7],
        };
        ext = match.ext;
        //var mmmm = resize_type.match(/^(?<ext>jpg|webp)_/);
        mmmm = resize_type.match(/^(jpg|webp)_/);
        if(mmmm) {
            //ext = mmmm.groups.ext;
            ext = mmmm[1];
        }
        return protocol+'://'+match.host+'/'+prefix+match.a+'/'+match.b+'/'+match.c+'.'+ext;
    }

    var index = src.lastIndexOf('/');
    var suffix = src.substring(index);
    return src.substring(0, index) + "/_" + resize_type + "/" + w + "/" + h + suffix.substring(0, 3) + "/" + suffix.substring(3, 5) + "/" + suffix.substring(5);


}
function base_url(path) {
    return 'https://www.lnwshop.com'+(path || '');
}
function img_src(path,type,w,h,resize_type){
    if(typeof path == 'string'){
        if(path.match(/\.pdf$/)){
            type = 'pdf';
        }else if(path.match(/\.swf$/)){
            type = 'swf';
        }
    }
    if(type=='pdf'){
        var img_size = 32;
        if(w>=150){
            img_size = 150;
        }else if(w>=125){
            img_size = 125;
        }else if(w>=64){
            img_size = 100;
        }
        return base_url('images/pdf_'+img_size+'.png');
    }else if(type=='swf'){
        img_size = 32;
        if(w>=150){
            img_size = 150;
        }else if(w>=125){
            img_size = 125;
        }else if(w>=64){
            img_size = 100;
        }
        return base_url('images/swf_'+img_size+'.png');
    }
    if(path == null || path == '' || typeof path != 'string'){
        if(type == null){
            return base_url('images/blank.gif');
        }else if(type=='product'){
            return base_url('images/product/no_pic_other.jpg');
        }else if(type=='user'){
            return base_url('images/noAvatar.png');
        }else if(type=='shop'){
            return base_url('images/noAvatarShop.png');
        }else if(type=='album'){
            return base_url('images/noAlbumPic.jpg');
        }else if(type=='blog'){
            return base_url('images/article_nopicture.jpg');
        }else{
            return base_url('images/noAlbumPic.jpg');
        }
    }
    if(typeof resize_type == 'undefined')
        resize_type = 'resize';

    if(is_img_lnwfile(path)){
        path = lnwfile_fullsize(path);
        if(w != null || h != null){
            if(w == h) {
                if(w <= 20)   { w = 20; h = 20; }
                else if(w <= 50)  { w = 50; h = 50; }
                else if(w <= 64)  { w = 64; h = 64; }
                else if(w <= 100) { w = 100;  h = 100;  }
                else if(w <= 125) { w = 125;  h = 125;  }
                else if(w <= 150) { w = 150;  h = 150;  }
                else if(w <= 200) { w = 200;  h = 200;  }
                else if(w <= 300) { w = 300;  h = 300;  }
                else  { w = 300;  h = 300;  }
            }
            var qs = '';
            if(w != null && h != null){
                return src_mini(path,w,h,resize_type);
            }else if(w != null){
                qs = '?w='+w;
            }else{
                qs = '?h='+h;
            }
            return path+qs;
        }
        return path;

    }else if(path.match(/^http(s)?:\/\//)){
        return path;
    }else if(path.indexOf('http://')==0){
        return path;
    }else{
        return base_url(path);
    }
}
function generateRandomKey(length){
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomKey = '';

    for(let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomKey += characters.charAt(randomIndex);
    }

    return randomKey;
}

const state = {
    access_token: getAccessToken('lnw_access_token'),

    // ไว้เก็บว่าเคยดึงข้อมูลอะไรมาบ้างแล้ว จะได้ไม่ต้องดึงซ้ำอีก
    called: {
        fetchWebPushTokens: false,
        fetchLineTokens: false,
        fetchNotifications: false,
        fetchNotificationsCount: false
    },

    paginated_metadata: {
        /**
         * ถ้าจะเพิ่ม properties ตรงนี้ ให้ไปเพิ่มที่ mutations.CLEAR_PAGINATED_METADATA ด้วย
         */
        receiverAddresses: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: false, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        taxAddresses: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: false, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        dealerReceiverAddresses: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: false, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
        senderAddresses: {
            items: {},
            sortedItems: [],
            allMoreThanPageLimit: false, // ถ้าทั้งหมดมีมากกว่า ที่เราแสดง
            keyword: false,
            offset: 0,
            more: false, // ยังเหลืออีกหรือเปล่า
            firstFetched: false, // เคยดึงมาซักครั้งแล้วหรือยัง
        },
    },

    userData: null,
    favShops: null,
    myShops: null,
    highlightShops: null,
    line_notify_tokens: null,
    web_push_tokens: null,
    notifications: null,
    notifications_count: null,
    subscriptionStatus: null,
    is_loading_msg: null,
    lnwmsg_num: null,
    lnwmsg_offset: null,
    accountExists: {
        by: null,
        name: null,
        avatar: null,
    },
    activeDevice: {
        os: '',
        device: '',
        browser: '',
    },
}
const getters = {
    axios_headers: (state) => {
        if(state.access_token){
            return {
                'X-ACCESS-TOKEN': state.access_token,
            }
        }else{
            return null
        }
    },
    hasAccessToken: (state) => !!state.access_token,
    isLogin: (state) => !!state.userData && !!state.access_token,
    loggedInEmail: (state) => state.userData ? state.userData.email : false,
    loggedInUserId: (state) => state.userData ? state.userData.user_id : null,
    userData: (state) => state.userData,
    favShops: (state) => state.favShops,
    orderDataReceiverId: (state, getters, rootState, rootGetters) => {
        return rootGetters['order/order'].receiver_id;
    },
    orderDataSenderId: (state, getters, rootState, rootGetters) => {
        return rootGetters['order/order'].dealer_id;
    },
    orderDataReceiverKey: (state, getters, rootState, rootGetters) => {
        return rootGetters['order/order'].receiver.contact_key;
    },
    receiverAddressNotExists: (state, getters) => {
        if(!getters.orderDataReceiverId || (!['receiver', 'guest_receiver'].includes(getters.orderDataReceiverKey)))
            return false;

        if(Object.keys(state.paginated_metadata.receiverAddresses.items).length === 0)
            return true;

        return typeof state.paginated_metadata.receiverAddresses.items[getters.orderDataReceiverId] === 'undefined';
    },
    dealerReceiverAddressNotExists: (state, getters) => {
        if(!getters.orderDataReceiverId || getters.orderDataReceiverKey != 'dealer_receiver')
            return false;

        if(Object.keys(state.paginated_metadata.dealerReceiverAddresses.items).length === 0)
            return true;

        return typeof state.paginated_metadata.dealerReceiverAddresses.items[getters.orderDataReceiverId] === 'undefined';
    },
    senderAddressNotExists: (state, getters) => {
        if(!getters.orderDataSenderId)
            return false;

        if(Object.keys(state.paginated_metadata.senderAddresses.items).length === 0)
            return true;

        return typeof state.paginated_metadata.senderAddresses.items[getters.orderDataSenderId] === 'undefined';
    },
    use_this_sortedReceiverAddresses: (state, getters) => {
        if(getters.receiverAddressNotExists){
            let sortedReceiverAddresses = [...state.paginated_metadata.receiverAddresses.sortedItems];
            sortedReceiverAddresses.unshift(getters.orderDataReceiverId);
            return sortedReceiverAddresses;
        }else{
            return state.paginated_metadata.receiverAddresses.sortedItems;
        }
    },
    use_this_sortedDealerReceiverAddresses: (state, getters) => {
        if(getters.dealerReceiverAddressNotExists){
            let sortedDealerReceiverAddresses = [...state.paginated_metadata.dealerReceiverAddresses.sortedItems];
            sortedDealerReceiverAddresses.unshift(getters.orderDataReceiverId);
            return sortedDealerReceiverAddresses;
        }else{
            return state.paginated_metadata.dealerReceiverAddresses.sortedItems;
        }
    },
    use_this_sortedSenderAddresses: (state, getters) => {
        if(getters.senderAddressNotExists){
            let sortedSenderAddresses = [...state.paginated_metadata.senderAddresses.sortedItems];
            sortedSenderAddresses.unshift(getters.orderDataSenderId);
            return sortedSenderAddresses;
        }else{
            return state.paginated_metadata.senderAddresses.sortedItems;
        }
    },
    // สำหรับเพิ่ม receiverAddress หลอก สำหรับ user ที่ไม่ได้เป็นเจ้าของ order.receiver
    use_this_receiverAddresses: (state, getters, rootState, rootGetters) => {
        if(getters.receiverAddressNotExists){

            let receiverAddresses = Object.assign({}, state.paginated_metadata.receiverAddresses.items);

            receiverAddresses[getters.orderDataReceiverId] = Object.assign({}, rootGetters['order/order'].receiver);
            receiverAddresses[getters.orderDataReceiverId].id = getters.orderDataReceiverId;
            receiverAddresses[getters.orderDataReceiverId].dummy = true;

            return receiverAddresses;
        }else{
            return state.paginated_metadata.receiverAddresses.items;
        }
    },
    // สำหรับเพิ่ม dealerReceiverAddress หลอก สำหรับ user ที่ไม่ได้เป็นเจ้าของ order.receiver
    use_this_dealerReceiverAddresses: (state, getters, rootState, rootGetters) => {
        if(getters.dealerReceiverAddressNotExists){
            let dealerReceiverAddresses = Object.assign({}, state.paginated_metadata.dealerReceiverAddresses.items);

            dealerReceiverAddresses[getters.orderDataReceiverId] = Object.assign({}, rootGetters['order/order'].receiver);
            dealerReceiverAddresses[getters.orderDataReceiverId].id = getters.orderDataReceiverId;
            dealerReceiverAddresses[getters.orderDataReceiverId].dummy = true;

            return dealerReceiverAddresses;
        }else{
            return state.paginated_metadata.dealerReceiverAddresses.items;
        }
    },
    // สำหรับเพิ่ม senderReceiverAddress หลอก สำหรับ user ที่ไม่ได้เป็นเจ้าของ order.dealer
    use_this_senderAddresses: (state, getters, rootState, rootGetters) => {
        if(getters.senderAddressNotExists){
            let senderAddresses = Object.assign({}, state.paginated_metadata.senderAddresses.items);

            senderAddresses[getters.orderDataSenderId] = Object.assign({}, rootGetters['order/order'].dealer);
            senderAddresses[getters.orderDataSenderId].id = getters.orderDataReceiverId;
            senderAddresses[getters.orderDataSenderId].dummy = true;

            return senderAddresses;
        }else{
            return state.paginated_metadata.senderAddresses.items;
        }
    },
    // เวลาจะเอาไปแสดง list ตัวเลือก ให้ดึงจากตรงนี้แทน เพราะจะเอา receipt ที่กำลังใช้งาน แต่ไม่อยู่ในตัวเลือก ให้มันสามารถแสดงได้
    use_this_taxAddresses: (state, getters, rootState) => {
        const receipts = Object.assign({}, state.paginated_metadata.taxAddresses.items);

        if(rootState.order.orderData?.receipt?.contact_id && typeof receipts[rootState.order.orderData.receipt.contact_id] === 'undefined'){
            receipts[rootState.order.orderData.receipt.contact_id] = Object.assign({}, rootState.order.orderData.receipt);
        }

        return receipts;
    },
    use_this_sortedTaxAddresses: (state, getters, rootState) => {
        const receipt_ids = [];
        let found = false;
        for(const receipt_id of state.paginated_metadata.taxAddresses.sortedItems) {
            if(rootState.order.orderData.receipt?.contact_id + '' === receipt_id + ''){
                found = true;
            }
            receipt_ids.push(receipt_id);
        }

        if(rootState.order.orderData.receipt?.contact_id && !found)
            receipt_ids.unshift(rootState.order.orderData.receipt?.contact_id);

        return receipt_ids;
    },
    subscribedDeviceTokenID:(state) => {
        let current_subscription = JSON.stringify(state.subscriptionStatus);
        let id = false;
        if(state.web_push_tokens && state.web_push_tokens.length){
            state.web_push_tokens.forEach(function(web_push_token){
                if(web_push_token.device_token == current_subscription){
                    id = web_push_token.device_token_id;
                }
            });
        }
        return id;
    },
}
const mutations = {
    setAccessToken: (state, access_token) => {
        state.access_token = access_token;
    },
    setUserData: (state, payload) => {
        if(typeof payload.access_token !== 'undefined'){
            state.access_token = payload.access_token;
        }else if(typeof payload.lnw_access_token !== 'undefined'){
            state.access_token = payload.lnw_access_token;
        }
        state.userData = payload.userData;
    },

    setFavShops: (state, payload) => {
        state.favShops = payload;
    },
    setMyShops: (state, payload) => {
        state.myShops = payload;
    },
    setHighlightShops: (state, payload) => {
        state.highlightShops = payload;
    },
    setSubscriptionStatus: (state, payload) => {
        state.subscriptionStatus = payload;
    },
    [SET_CALLED]: (state, {methodName, flag}) => state.called[methodName] = !!flag,
    [SET_LINE_NOTIFY_TOKENS]: (state, line_notify_tokens) => state.line_notify_tokens = line_notify_tokens,
    [SET_WEB_PUSH_TOKENS]: (state, web_push_tokens) => state.web_push_tokens = web_push_tokens,
    [SET_NOTIFICATIONS]: (state, notifications) => {
        if (state.notifications) {
            state.notifications.push(...notifications);
        }else{
            state.notifications = notifications;
        }
    },
    [SET_NOTIFICATIONS_COUNT]: (state, notifications_count) => state.notifications_count = notifications_count,
    [SET_IS_LOADING_MSG]: (state, flag) => state.is_loading_msg = flag,
    [SET_LNWMSG_NUM]: (state, num) => state.lnwmsg_num = num,
    [SET_LNWMSG_OFFSET]: (state, offset) => state.lnwmsg_offset = offset,

    [CLEAR_PAGINATED_METADATA]: (state) => {
        ['receiverAddresses', 'taxAddresses', 'dealerReceiverAddresses', 'senderAddresses'].forEach(type => {
            state.paginated_metadata[type].sortedItems = [];
            state.paginated_metadata[type].items = {};
            state.paginated_metadata[type].allMoreThanPageLimit = false;
            state.paginated_metadata[type].keyword = false;
            state.paginated_metadata[type].offset = 0;
            state.paginated_metadata[type].more = false;
            state.paginated_metadata[type].firstFetched = false;
        });
    },


    [SET_SEARCH_ADDRESSES_RESULT]: (state, {type, payload, keyword}) => {
        if(typeof payload !== 'object' || payload === null)
            return;

        if(typeof keyword !== "undefined"){
            state.paginated_metadata[type].sortedItems = [];
            state.paginated_metadata[type].offset = 0;
            state.paginated_metadata[type].keyword = keyword ? keyword : false;
        }

        if(Array.isArray(payload[type])){
            const new_items = {...state.paginated_metadata[type].items};
            payload[type].forEach(item => {
                const key = item.contact_id;
                if(!state.paginated_metadata[type].sortedItems.includes(key))
                    state.paginated_metadata[type].sortedItems.push(key);
                if(typeof state.paginated_metadata[type].items[key] === "undefined")
                    new_items[key] = item;
            });
            state.paginated_metadata[type].items = new_items;


            if(typeof payload[`${type}_more`] !== "undefined")
                state.paginated_metadata[type].more = payload[`${type}_more`];

            if(!state.paginated_metadata[type].keyword){
                state.paginated_metadata[type].firstFetched = true;

                if(state.paginated_metadata[type].offset === 0 && state.paginated_metadata[type].more){
                    state.paginated_metadata[type].allMoreThanPageLimit = true;
                }
            }

            state.paginated_metadata[type].offset += payload[type].length;

        }
    },

    setAccountExists: (state, payload) => {
        state.accountExists.by = payload.exists;
        state.accountExists.name = payload.name;
        state.accountExists.avatar = payload.avatar;
    },
    setActiveDevice: (state, payload) => {
        state.activeDevice = payload;
    }
}
const actions = {
    async initUserData({commit, dispatch}){
        let access_token = getAccessToken();
        if(access_token){
            commit('setAccessToken', access_token);
            return dispatch('fetchUser');
        }else{
            // ยิงเพื่อให้รู้ว่า login อยู่หรือเปล่า (จาก cookie)
            return Axios.get(ApiUrl.account('check_session'))
                .then(response => {
                    if(response.data && response.data.data && response.data.data.lnw_access_token){
                        setAccessToken(response.data.data.lnw_access_token);
                        commit('setUserData', response.data.data);
                    }
                });
        }
    },
    async fetchUser({commit, state}){
        return Axios.get(ApiUrl.account('profile'), {
            headers: {
                'X-ACCESS-TOKEN': state.access_token
            },
        })
            .then((response) => {
                commit('setUserData', response.data.data);
            })
            .catch(() => {
                clearAccessToken(); // ถ้ายิงล้มเหลว ก็เคลีย session ใน sessionStorage
                commit('setAccessToken', null);
            });
    },
    detectEnvironment({commit,state}){
        const userAgent = navigator.userAgent;
        if(state.activeDevice.os == ''){
            let activeDevice = {
                os: '',
                device: '',
                browser: '',
            };
            if(/android/i.test(userAgent)){
                activeDevice.device = 'mobile';
                activeDevice.os = 'android';
                activeDevice.browser = /chrome/i.test(userAgent) ? 'chrome' : 'unknown';
            }else if(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream){
                activeDevice.device = 'mobile';
                activeDevice.os = 'ios';
                activeDevice.browser = /safari/i.test(userAgent) ? 'safari' : 'unknown';
            }else if(/Macintosh/.test(userAgent)){
                activeDevice.device = 'desktop';
                activeDevice.os = 'macos';
                activeDevice.browser = /chrome/i.test(userAgent) ? 'chrome' : /safari/i.test(userAgent) ? 'safari' : /firefox/i.test(userAgent) ? 'firefox' : 'unknown';
            }else if(/Windows/.test(userAgent)){
                activeDevice.device = 'desktop';
                activeDevice.os = 'windows';
                activeDevice.browser = /chrome/i.test(userAgent) ? 'chrome' : 'unknown';
            }
            commit('setActiveDevice', activeDevice);
        }
    },
    async setSubscriptionStatus({commit}, payload){
        commit('setSubscriptionStatus', payload);
    },
    async addFavShop({commit, state}, payload){
        return Axios.post(ApiUrl.lnwshopweb('user/add_favshop'), {
            shop_id: payload.shop_id
        }, {
            headers: {
                'X-ACCESS-TOKEN': state.access_token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (response.data && !response.data.error) {
                    commit('setFavShops', response.data.shops);
                }
            });
    },
    async removeFavShop({commit, state}, payload) {
        return Axios.post(ApiUrl.lnwshopweb('user/remove_favshop'), {
            shop_id: payload.shop_id
        }, {
            headers: {
                'X-ACCESS-TOKEN': state.access_token,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (response.data && !response.data.error) {
                    commit('setFavShops', response.data.shops);
                }
            });
    },
    async fetchFavShops({commit, state}){
        return Axios.get(ApiUrl.lnwshopweb('user/favshop'), {
            headers: {
                'X-ACCESS-TOKEN': state.access_token
            },
        })
            .then((response) => {
                if(response.data && !response.data.error){
                    commit('setFavShops', response.data.shops);
                }
            })
    },
    async fetchMyShops({commit, state}){
        return Axios.get(ApiUrl.lnwshopweb('user/myshop'), {
            headers: {
                'X-ACCESS-TOKEN': state.access_token
            },
        })
            .then((response) => {
                if(response.data && !response.data.error){
                    commit('setMyShops', response.data.shops);
                }
            })
    },
    async fetchHighlightShops({commit, state}){
        return Axios.get(ApiUrl.lnwshopweb('user/highlightshop'), {
            headers: {
                'X-ACCESS-TOKEN': state.access_token
            },
        })
            .then((response) => {
                if(response.data && !response.data.error){
                    commit('setHighlightShops', response.data.shops);
                }
            })
    },
    async fetchLineTokens({commit, state, rootGetters}){

        const randomKey = 'lnw' + generateRandomKey(16);
        const url = 'jsonp/list_line_notify_tokens?lnwmsg_jsonp=' + randomKey;
        return Axios.get(ApiUrl.lnwmsg(url),
            {
                headers: rootGetters['order/axios_headers'],
                params: {
                    data: state.access_token,
                    limit: 10,
                    ajaxxxx: true,
                }
            })
            .then((response) => {
                if(response.data.includes(randomKey)){
                    const json_response = response.data.substring(response.data.indexOf('{'), response.data.lastIndexOf('}') + 1);

                    // Parse the JSON object
                    const parse_response = JSON.parse(json_response);

                    if(!parse_response.error){
                        commit(SET_LINE_NOTIFY_TOKENS, parse_response.tokens);
                        commit(SET_CALLED, {methodName: 'fetchLineTokens', flag: true});
                    }
                }
            });
    },
    async fetchWebPushTokens({commit}){
        return Axios.get(ApiUrl.lnwmsg("auth/list_web_push_tokens"))
            .then((response) => {
                if(response.data.success){
                    if(response.data.tokens){
                        commit(SET_WEB_PUSH_TOKENS, response.data.tokens);
                        commit(SET_CALLED, {methodName: 'fetchWebPushTokens', flag: true});
                    }
                }
            });
    },
    async readNotifications({commit, state, rootGetters}) {
        const randomKey = 'lnw' + generateRandomKey(16);
        const url = 'jsonp/read?lnwmsg_jsonp=' + randomKey;
        return Axios.get(ApiUrl.lnwmsg(url),
            {
                headers: rootGetters['order/axios_headers'],
                params: {
                    data: state.access_token,
                    ajaxxxx: true,
                }
            })
            .then((response) => {
                if(response.data.includes(randomKey)){
                    const json_response = response.data.substring(response.data.indexOf('{'), response.data.lastIndexOf('}') + 1);

                    const parse_response = JSON.parse(json_response);
                    if(parse_response){
                        commit(SET_NOTIFICATIONS_COUNT, 0);
                    }
                }
            });
    },
    async fetchNotificationsCount({commit, state, rootGetters}){
        const randomKey = 'lnw' + generateRandomKey(16);
        const url = 'jsonp/count_notifications?lnwmsg_jsonp=' + randomKey;
        return Axios.get(ApiUrl.lnwmsg(url),
            {
                headers: rootGetters['order/axios_headers'],
                params: {
                    data: state.access_token,
                    ajaxxxx: true,
                }
            })
            .then((response) => {
                if(response.data.includes(randomKey)){
                    const json_response = response.data.substring(response.data.indexOf('{'), response.data.lastIndexOf('}') + 1);

                    // Parse the JSON object
                    const parse_response = JSON.parse(json_response);

                    if(parse_response.success && parse_response.count != null){
                        commit(SET_NOTIFICATIONS_COUNT, parse_response.count);
                        commit(SET_CALLED, {methodName: 'fetchNotificationsCount', flag: true});
                    }
                }
            });
    },
    async fetchNotifications({commit, state, rootGetters}){
        if(state.is_loading_msg){
            return;
        }
        const limit = 10;
        if(!state.called.fetchNotifications){
            commit(SET_LNWMSG_NUM, 0);
            commit(SET_LNWMSG_OFFSET, 0);
        }else if(state.lnwmsg_num < state.lnwmsg_offset){
            return;
        }
        const randomKey = 'lnw' + generateRandomKey(16);
        const url = 'jsonp/notifications?lnwmsg_jsonp=' + randomKey;
        const data = {
            data: state.access_token,
            offset: state.lnwmsg_offset,
            limit: limit,
            ajaxxxx: true,
        };
        state.is_loading_msg = true;

        function text(value){
            if(typeof value == 'string'){
                return value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
            }else if(value == null){
                return '';
            }
            return value;
        }

        commit(SET_IS_LOADING_MSG, true);
        return Axios.get(ApiUrl.lnwmsg(url), {
            headers: rootGetters['order/axios_headers'],
            params: data,
        })
            .then((response) => {
                if(response.data.includes(randomKey)){
                    const json_response = response.data.substring(response.data.indexOf('{'), response.data.lastIndexOf('}') + 1);

                    // Parse the JSON object
                    const parse_response = JSON.parse(json_response);

                    if(parse_response.success){
                        const notifications = parse_response.notifications.map((nt) => {
                            /*let li_class = '';
                            switch (nt.type) {
                                case 'lnwshop/owner/webboard/topic':
                                    li_class = 'nTopic';
                                    break;
                                case 'lnwshop/owner/webboard/post':
                                case 'lnwshop/user/webboard/post':
                                    li_class = 'nPost';
                                    break;
                                case 'lnwshop/owner/contact_us':
                                    li_class = 'nMessage';
                                    break;
                                case 'lnwshop/owner/product/discuss':
                                    li_class = 'nDiscus';
                                    break;
                                case 'lnwshop/owner/product/review':
                                    li_class = 'nRating';
                                    break;
                                case 'lnwshop/owner/payment':
                                case 'lnwshop/user/payment':
                                    li_class = 'nPayment';
                                    break;
                                case 'lnwshop/user/order':
                                case 'lnwshop/owner/order':
                                case 'lnwshop/user/shipping':
                                case 'lnwshop/owner/shipping':
                                case 'lnwmall2/seller/order':
                                case 'lnwmall2/buyer/order':
                                case 'lnwmall2/seller/shipping':
                                case 'lnwmall2/buyer/shipping':
                                    li_class = 'nOrder';
                                    break;
                                case 'lnwshop/owner/broadcast':
                                    li_class = 'nNotice';
                                    break;
                                case 'lnwpay/seller/order/created':
                                    li_class = 'npsCreated';
                                    break;
                                case 'lnwpay/buyer/order/created':
                                    li_class = 'npbCreated';
                                    break;
                                case 'lnwpay/buyer/payment/informed':
                                    li_class = 'npbInformed';
                                    break;
                                case 'lnwpay/buyer/payment/rejected':
                                    li_class = 'npbRejected';
                                    break;
                                case 'lnwpay/seller/payment/confirmed':
                                case 'lnwpay/buyer/payment/confirmed':
                                case 'lnwshop/user/payment_confirm':
                                    li_class = 'npConfirmed';
                                    break;
                                case 'lnwpay/buyer/order/shipped':
                                case 'lnwshop/user/shipping_confirm':
                                    li_class = 'npbShipped';
                                    break;
                                case 'lnwpay/buyer/order/received':
                                    li_class = 'npbReceived';
                                    break;
                                case 'lnwpay/seller/order/completed':
                                    li_class = 'npsCompleted';
                                    break;
                            }*/

                            let nt_extra = {};
                            if(typeof nt.extra == 'undefined' || nt.extra == null){
                                nt_extra = {};
                            }else if(typeof nt.extra == 'string'){
                                nt_extra = JSON.parse(nt.extra);
                            }else{
                                nt_extra = nt.extra;
                            }

                            let link = '';
                            if(typeof nt_extra.url != 'undefined'){
                                let href = nt_extra.url;
                                if(typeof nt.tmp_secret != 'undefined'){
                                    href = `https://lnwmsg.com/url?n=${nt.notification_id}&t=${nt.tmp_secret}`;
                                }
                                link = href;
                            }

                            let avatar = '';
                            let format = '';
                            const nt_type = nt.type.split('/');
                            if(nt_type.length > 2){
                                if(nt_type[1] == 'owner'){
                                    if(typeof nt_extra.user_avatar != 'undefined'){
                                        if(nt_extra.user_avatar == "https://lnw.me/images/guest.png"){
                                            avatar = nt_extra.user_avatar;
                                        }else{
                                            avatar = img_src(nt_extra.user_avatar, 'user', 50, 50);
                                        }
                                        format = 'user';
                                    }else if(typeof nt_type[2] != 'undefined' && nt_type[2] == 'broadcast'){
                                        avatar = state.user.userData.avatar;
                                        format = 'shop';
                                    }
                                }else if(nt_type[1] == 'user' && nt_type[2] == 'webboard'){
                                    if(typeof nt_extra.user_avatar != 'undefined'){
                                        avatar = img_src(nt_extra.user_avatar, 'user', 50, 50);
                                        format = 'user';
                                    }
                                }else if(nt_type[1] == 'user'){
                                    if(typeof nt_extra.owner_avatar != 'undefined'){
                                        avatar = img_src(nt_extra.owner_avatar, 'user', 50, 50);
                                        format = 'shop';
                                    }
                                }else if(nt_type[1] == 'buyer'){
                                    if(typeof nt_extra.seller_avatar != 'undefined'){
                                        avatar = img_src(nt_extra.seller_avatar, 'user', 50, 50);
                                        format = 'shop';
                                    }
                                }else if(nt_type[1] == 'seller'){
                                    if(typeof nt_extra.buyer_avatar != 'undefined'){
                                        avatar = img_src(nt_extra.buyer_avatar, 'user', 50, 50);
                                        format = 'user';
                                    }
                                }
                            }

                            let nt_message = nt.message;
                            nt_message = text(nt_message);
                            nt_message = nt_message.replace('[b][/b]', '');
                            nt_message = nt_message.replace(/\[b\](.+?)\[\/b\]/g, '<b>$1</b>');
                            nt_message = nt_message.replace('[i][/i]', '');
                            nt_message = nt_message.replace(/\[i\](.+?)\[\/i\]/g, '<i>$1</i>');

                            return {
                                notification_id: nt.notification_id,
                                // li_class,
                                link,
                                avatar,
                                format,
                                message: nt_message,
                                time: nt.time,
                                time_diff: nt.time_diff,
                                status: nt.status,
                            };
                        });
                        commit(SET_NOTIFICATIONS, notifications);
                        commit(SET_LNWMSG_NUM, state.lnwmsg_num + notifications.length);
                        commit(SET_LNWMSG_OFFSET, state.lnwmsg_offset + limit);
                        commit(SET_CALLED, {methodName: 'fetchNotifications', flag: true});
                    }
                }
                commit(SET_IS_LOADING_MSG, false);
            });
    },

    async fetchUserResources({commit, state}, {preferred_data}){
        if(preferred_data.length === 0)
            return;

        return Axios.get(ApiUrl.account('resources'), {
            headers: {
                'X-ACCESS-TOKEN': state.access_token
            },
            params: {
                preferred_data
            },
        })
            .then(response => {
                commit(SET_SEARCH_ADDRESSES_RESULT, {payload: response.data.data, type: 'receiverAddresses'});
                commit(SET_SEARCH_ADDRESSES_RESULT, {payload: response.data.data, type: 'taxAddresses'});
                commit(SET_SEARCH_ADDRESSES_RESULT, {payload: response.data.data, type: 'dealerReceiverAddresses'});
                commit(SET_SEARCH_ADDRESSES_RESULT, {payload: response.data.data, type: 'senderAddresses'});
            });
    },
    async checkAccountExists({commit}, {email, mobileNumber}){
        commit('order/setForm', {ajaxBuyerInfoAccountExistsRunning: true}, {root: true});
        return Axios.get(ApiUrl.account('is_account_exists'), {
            params: {
                email,
                mobile: mobileNumber,
            },
        })
            .then((response) => {
                commit('setAccountExists', response.data.data);
            })
            .finally(() => commit('order/setForm', {ajaxBuyerInfoAccountExistsRunning: false}, {root: true}));
    },
    async displayErrorAfterAuthenticate({state}, response){
        let error_message;
        // ไม่แน่ใจต้องใช้งานยังไงโดยที่ไม่ต้องมี param ที่ 1 ของ function นี้ เลยใส่ state ไว้เฉยๆ
        if(state && response.data){
            if(Array.isArray(response.data))
                error_message = response.data.join(', ');
            else if(response.data.error_message && typeof response.data.error_message === 'object'){
                error_message = '';
                for(let k in response.data.error_message) {
                    error_message += `${response.data.error_message[k]}\n`;
                }
            }else if(response.data.error_message && typeof response.data.error_message === 'string'){
                error_message = response.data.error_message;
            }else
                error_message = JSON.stringify(response.data);
        }else
            error_message = 'undefined error_message';
        await store.dispatch('order/setIsAjaxResponse', false);
        await store.dispatch('order/setIsAjaxResponse', true);
        await store.dispatch('order/setIsAjaxResponseMessage', error_message);
        return false;
    },
    async singlePurposeAuthenticateByUsernamePassword({commit, dispatch}, {
        username, password, encoded_user_id,
        onFinally,
        onSuccess,
    }){
        const postdata = {
            password: password,
            persistent: 1,
        }

        if(encoded_user_id)
            postdata.encoded_user_id = encoded_user_id
        else
            postdata.username = username

        try {
            const response = await Axios.post(ApiUrl.account('login_by_username'), postdata);
            if(response.data.success){
                if(response.data.data.twoFactorToken){
                    return {
                        twoFactorToken: response.data.data.twoFactorToken
                    }
                }else{
                    setAccessToken(response.data.data.lnw_access_token);
                    commit('setUserData', response.data.data);
                    if(onSuccess && typeof onSuccess === 'function')
                        onSuccess()
                    return true;
                }
            }else{
                return await dispatch('displayErrorAfterAuthenticate', response.data);

            }
        } catch(e) {
            // what to do ??
        } finally {
            if(onFinally && typeof onFinally === 'function')
                onFinally()
        }
    },
    async authenticateByUsernamePassword({commit, dispatch}, payload){
        commit('order/setForm', {ajaxAuthenticateByUsernamePasswordRunning: true}, {root: true});

        const postdata = {
            password: payload.password,
            persistent: 1,
        };

        if(payload.encoded_user_id)
            postdata.encoded_user_id = payload.encoded_user_id;
        else
            postdata.username = payload.username;

        try {
            const response = await Axios.post(ApiUrl.account('login_by_username'), postdata);
            if(response.data.success){
                if(response.data.data.twoFactorToken){
                    return {
                        twoFactorToken: response.data.data.twoFactorToken
                    }
                }else{
                    await dispatch('afterAuthenticateGlobal', {
                        ...payload,
                        lnw_access_token: response.data.data.lnw_access_token,
                        user: response.data.data,
                    });
                    return true;
                }
            }else{
                return await dispatch('displayErrorAfterAuthenticate', response.data);
            }
        } catch(e) {
            //
        } finally {
            commit('order/setForm', {ajaxAuthenticateByUsernamePasswordRunning: false}, {root: true});
        }
    },
    async verifyOtp({dispatch}, payload){
        const postdata = {
            code: payload.twoFactorCode,
            twoFactorToken: payload.twoFactorToken,
        }
        try {
            const response = await Axios.post(ApiUrl.account('verify_otp'), postdata)
            if(response.data.success){
                await dispatch('afterAuthenticateGlobal', {
                    ...payload,
                    lnw_access_token: response.data.data.lnw_access_token,
                    user: response.data.data,
                    justLogin: payload.justLogin
                })
                return true
            }else{
                return await dispatch('displayErrorAfterAuthenticate', response.data);
            }
        } catch(e) {
            alert(e);
            return false;
        }

    },
    async logout({commit, state, dispatch}){
        try {
            await Axios.post(ApiUrl.account('logout'), {}, {
                headers: {
                    'X-ACCESS-TOKEN': state.access_token
                },
            });

            clearAccessToken();

            commit('setUserData', {
                access_token: null,
                userData: null,
            });
            commit(CLEAR_PAGINATED_METADATA);
            commit('myOrders/CLEAR_PAGINATED_ORDERS', null, {root: true});

            await dispatch('order/fetchOrder', null, {root: true});
        } catch(e) {
            //
        }
    },

    async afterAuthenticateGlobal({commit, dispatch, getters, rootGetters}, payload){
        setAccessToken(payload.lnw_access_token);
        commit('setUserData', payload.user);
        if(typeof payload.then === 'function'){
            await payload.then({dispatch});
        }else if(payload.justLogin){
            await store.dispatch('order/setIsLoginRequired', false);
        }else{
            dispatch('order/tracker_login', {method: 'user-id'}, {root: true});
            await store.dispatch('order/setIsLoginRequired', false);

            // หลังจาก login ให้ fbq init ใหม่ด้วย external_id เพราะตอนเข้าหน้าเว็บมาตอนแรก init ครั้งแรก ไม่ได้ระบุ external_id ไปด้วย
            if(Array.isArray(rootGetters['order/both_facebook_pixel_ids'])
                && rootGetters['order/both_facebook_pixel_ids'].length){
                for(const id of rootGetters['order/both_facebook_pixel_ids']) {
                    fbq('init', id, {external_id: getters.loggedInUserId});
                }
            }

            // claim order
            await dispatch('order/claimOrder', null, {root: true});
        }
    },

    /**
     * ค้นหา
     * type: 'receiverAddresses' , 'taxAddresses' , 'dealerReceiverAddresses' , 'senderAddresses'
     */
    async searchAddresses({state, commit}, {type, keyword}){
        if(!valid_address_type(type))
            return false;

        if(typeof keyword === "undefined" || typeof keyword === "boolean")
            keyword = "";
        else
            keyword = keyword.trim();
        if(keyword === '')
            keyword = false;

        if(state.paginated_metadata[type].keyword !== keyword){
            return Axios.get(ApiUrl.account('resources'), {
                headers: {
                    'X-ACCESS-TOKEN': state.access_token
                },
                params: {
                    preferred_data: [type],
                    limit: default_paginated_limit,
                    offset: 0,
                    keyword: keyword ? keyword : null,
                },
            })
                .then(response => {
                    commit(SET_SEARCH_ADDRESSES_RESULT, {
                        type,
                        payload: response.data.data,
                        keyword: keyword,
                    });
                });
        }
    },

    async loadMoreAddresses({state, commit}, {type}){
        if(!valid_address_type(type))
            return false;

        return Axios.get(ApiUrl.account('resources'), {
            headers: {
                'X-ACCESS-TOKEN': state.access_token
            },
            params: {
                preferred_data: [type],
                limit: default_paginated_limit,
                offset: state.paginated_metadata[type].offset,
                keyword: state.paginated_metadata[type].keyword ? state.paginated_metadata[type].keyword : null,
            },
        })
            .then(response => {
                commit(SET_SEARCH_ADDRESSES_RESULT, {
                    type,
                    payload: response.data.data,
                });
            });
    },
}
export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};