import { render, staticRenderFns } from "./NotiPopup.vue?vue&type=template&id=ecbccb62&scoped=true"
import script from "./NotiPopup.vue?vue&type=script&lang=js"
export * from "./NotiPopup.vue?vue&type=script&lang=js"
import style0 from "./NotiPopup.vue?vue&type=style&index=0&id=ecbccb62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecbccb62",
  null
  
)

export default component.exports