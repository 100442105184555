<template>
    <div>

        <template v-if="isSelected">
            <v-tooltip top
                       max-width="200">
                <template v-slot:activator="{ on }">
                    <v-icon class="position-absolute"
                            style="right: 1.25rem; top: 1.25rem"
                            v-on="on">
                        mdi-information-variant
                    </v-icon>
                </template>
                {{ hint }}
            </v-tooltip>
        </template>
        <v-scroll-y-transition hide-on-leave>
            <div v-if="isSelected">
                <v-alert
                         class="transparent mt-4"
                         color="primary"
                         text>
                    <span class="small">{{ $t('คุณจะสามารถ Scan QR ได้หลังกดปุ่มยืนยันรายการแล้ว') }}</span>
                </v-alert>
                <div class="input-label-small mt-2">{{ $t('เลือกบัญชีธนาคารของร้านที่คุณจะ Scan QR') }} :</div>
                    <v-radio-group dense
                                   :rules="[v => !!v || $t('กรุณาเลือกบัญชีธนาคารด้วยนะคะ')]"
                                   v-model="selectedProvider"
                                   hide-details="auto">
                        <v-card outlined>
                            <v-list-item-group>
                                <div v-for="(bank, index) in myPaymentMethod.providers"
                                     :key="bank.key">
                                    <label>
                                        <v-list-item :class="{ 'selected': (bank.key === selectedProvider)}">
                                            <v-list-item-action>
                                                <v-radio :value="bank.key"></v-radio>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>
                                                    <b>{{ (bank.lang && bank.lang == 'พร้อมเพย์')?'Scan QR':bank.lang }}</b>
                                                    <div class="minor">
                                                    <span class="d-inline-block mr-2">
                                                        <template v-if="shopData.setting.show_account_number">
                                                            {{bank.detail.account_number}}
                                                        </template>
                                                        <template v-else>
                                                            {{ bank.detail.account_number.slice(0, 3) }}-xxxxxx-{{ bank.detail.account_number.substr(-1, 1) }}
                                                        </template>
                                                    </span>
                                                        <span class="d-inline-block">{{ bank.detail.account_name }}</span>
                                                    </div>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-icon>
                                                <template v-if="bank.detail?.icon">
                                                    <img class="m-0"
                                                         :alt="bank.detail.name"
                                                         :src="bank.detail.icon.url">
                                                </template>
                                                <template v-else>
                                                    <img class="m-0"
                                                         :alt="bank.detail?.name"
                                                         :src="`https://www.lnwpay.com/images/banks/${(bank.detail.account_key == 'PROMPTPAY'?'billpayment':bank.detail.account_key)}.jpg`">
                                                </template>
                                            </v-list-item-icon>
                                        </v-list-item>
                                    </label>
                                    <v-divider v-if="index !== myPaymentMethod.length -1"></v-divider>
                                </div>
                            </v-list-item-group>
                        </v-card>
                    </v-radio-group>
                <div class="buyerProtection"
                     v-if="myPaymentMethod.is_lnwpay">
                    <span>{{ $t('คุณจะได้รับความคุ้มครองผู้ซื้อโดย') }}</span>
                    <img style="height: 15px; vertical-align: text-top"
                         alt=""
                         src="@/assets/lnwpay_logo_gradient.png"/>
                </div>
            </div>
        </v-scroll-y-transition>
    </div>
</template>
<script>
import MixinsPaymentComponent from "./mixins/MixinsPaymentComponent.vue";
import {mapState} from 'vuex';
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "ThaiQrPayment",
    mixins: [MixinsPaymentComponent,MixinsHelper],
    data() {
        return {
            snackbar: false,
        };
    },
    computed: {
        ...mapState({
            shopData: state => state.order.shopData
        }),
        hint() {
            return this.$t('ชำระเงินด้วยวิธีการโอนเงินโดยตรงผ่านบัญชีธนาคารผ่าน App ของธนาคาร, เคาน์เตอร์ธนาคาร, ตู้ ATM, Internet Banking');
        },
    },
}
</script>
<style scoped></style>