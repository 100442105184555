import { render, staticRenderFns } from "./ClaimOrderComponent.vue?vue&type=template&id=1cfb6e60&scoped=true"
import script from "./ClaimOrderComponent.vue?vue&type=script&lang=js"
export * from "./ClaimOrderComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cfb6e60",
  null
  
)

export default component.exports